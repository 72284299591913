import styled from 'styled-components';
import Flex from './Flex';

const Row = styled(Flex)`
  @media (max-width: ${(p) => (p.collapseIndex ? p.theme.breakpoints[p.collapseIndex] : 0)}) {
    flex-direction: column;
  }
`;

Row.defaultProps = {
  collapseIndex: null,
  width: '100%',
  flexDirection: 'row',
  ...Flex.defaultProps,
};

export default Row;
