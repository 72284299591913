import { Types, isInvalidType, isString } from './utilities';

export default (config) => (value) => {
  if (isString(value)) {
    const expression = isString(config.regex) ? new RegExp(config.regex.replace(/\//g, '')) : config.regex;
    return !expression.test(value) ? config.message : null;
  }

  return isInvalidType(value, [Types.string]);
};
