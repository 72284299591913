import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  fontSize,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import Box from '../Box';

const textTransform = ({ uppercase, lowercase, capitalize }) => {
  if (uppercase) return 'uppercase';
  if (lowercase) return 'lowercase';
  if (capitalize) return 'capitalize';
  return 'initial';
};

const calcSize = ({
  xs, sm, md, lg, xl, ...p
}) => {
  if (xs) {
    return `
    font-size: ${p.theme.fontSizes[1]};
    font-weight: ${p.theme.fontWeights.text}
    line-height: ${p.theme.lineHeights.display};
  `;
  }
  if (sm) {
    return `
    font-size: ${p.theme.fontSizes[2]};
    font-weight: ${p.theme.fontWeights.text}
    line-height: ${p.theme.lineHeights.display};
  `;
  }
  if (md) {
    // TODO: add 18px in font-sizes
    return `
    font-size: 18px;
    font-weight: ${p.theme.fontWeights.text}
    line-height: ${p.theme.lineHeights.display};
  `;
  }
  if (lg) {
    return `
    font-size: ${p.theme.fontSizes[3]};
    font-weight: ${p.theme.fontWeights.text}
    line-height: ${p.theme.lineHeights.display};
  `;
  }
  if (xl) {
    return `
    font-size: ${p.theme.fontSizes[4]};
    font-weight: ${p.theme.fontWeights.text}
    line-height: ${p.theme.lineHeights.display};
  `;
  }
  return '';
};

const Text = styled(Box)`
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${letterSpacing}
  color: ${(p) => (p.muted ? p.theme.colors.muted : themeGet(`colors.${p.color}`, p.color)(p))};
  text-transform: ${textTransform};
  ${calcSize}
`;

Text.defaultProps = {
};

Text.propTypes = {
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
};

export default Text;
