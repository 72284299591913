import React from 'react';
import PropTypes from 'prop-types';
import styled, {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components';
import { override, themeOverrideProps, baseThemeProps } from '../../theme';

const GlobalStyle = createGlobalStyle`

html {
  box-sizing: border-box;
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.light};
}

*:focus {
  outline: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

*, *:before, *:after {
  box-sizing: inherit;
  font-family: inherit;
}

body {
  border: 0;
  margin: 0;
  background-color: ${(p) => p.theme.colors.base05};
  overflow-y: scroll;
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.light};
  color: ${(p) => p.theme.colors.base};
  font-size: ${(p) => p.theme.fontSizes[2]};
}

html, body, #root, #root > div {
  height: 100%;
}

a {
  color: ${(p) => p.theme.colors.primary};
}

a:active, a:visited {
  color: ${(p) => p.theme.colors.primary};
}

a:hover {
  color: ${(p) => p.theme.colors.primaryDark};
}


`;

export const Base = styled.div`
  font-family: ${(props) => props.theme.font};
  line-height: ${(props) => props.theme.lineHeights.standard};
  font-weight: ${(props) => props.theme.fontWeights.light};
  * {
    box-sizing: border-box;
  }
`;

const CustomThemeProvider = ({ theme, themeOverrides, children }) => {
  const newTheme = override(theme, themeOverrides);
  return (
    <StyledThemeProvider theme={newTheme}>
      <GlobalStyle />
      <Base {...newTheme}>{children}</Base>
    </StyledThemeProvider>
  );
};

CustomThemeProvider.defaultProps = {
  themeOverrides: null,
};

CustomThemeProvider.propTypes = {
  themeOverrides: PropTypes.shape({ colors: { ...themeOverrideProps.color } }),
  theme: baseThemeProps.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CustomThemeProvider;
