import hasChar from './hasChar';
import hasDigit from './hasDigit';
import hasLowercase from './hasLowercase';
import hasUppercase from './hasUppercase';
import isBlacklisted from './isBlacklisted';
import isEmail from './isEmail';
import isEqual from './isEqual';
import isExactLength from './isExactLength';
import isGreaterThan from './isGreaterThan';
import isLessThan from './isLessThan';
import isMaxLength from './isMaxLength';
import isMinLength from './isMinLength';
import isNotEqual from './isNotEqual';
import isNumber from './isNumber';
import isRegexMatch from './isRegexMatch';
import isRequired from './isRequired';
import isWhitelisted from './isWhitelisted';

const getValidator = (validatorName) => {
  switch (validatorName) {
    case 'hasChar':
      return hasChar;
    case 'hasDigit':
      return hasDigit;
    case 'hasLowercase':
      return hasLowercase;
    case 'hasUppercase':
      return hasUppercase;
    case 'isBlacklisted':
      return isBlacklisted;
    case 'isEmail':
      return isEmail;
    case 'isEqual':
      return isEqual;
    case 'isExactLength':
      return isExactLength;
    case 'isGreaterThan':
      return isGreaterThan;
    case 'isLessThan':
      return isLessThan;
    case 'isMaxLength':
      return isMaxLength;
    case 'isMinLength':
      return isMinLength;
    case 'isNotEqual':
      return isNotEqual;
    case 'isNumber':
      return isNumber;
    case 'isRegexMatch':
      return isRegexMatch;
    case 'isWhitelisted':
      return isWhitelisted;
    default:
      throw new Error(`${validatorName} is not a validator`);
  }
};

export default (config) => (value) => {
  const validator = getValidator(config.validator);
  if (validator({ ...config, value: config.validatorValue })(config.value) === null) {
    return isRequired(config)(value);
  }
  return null;
};
