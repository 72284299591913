import styled from 'styled-components';
import {
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';

const TH = styled.th`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${textAlign}
  ${border}
  ${boxShadow}
`;

TH.displayName = 'TH';

TH.defaultProps = {
  px: 5,
  py: 5,
};

TH.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
};

export default TH;
