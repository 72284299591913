import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'; import {
  space,
  fontSize,
  fontFamily,
  fontWeight,
  layout,
  textAlign,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import Text from '../Text';
import Box from '../Box';

const Heading = styled(Text)`
  margin: 0;
  ${space}
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${letterSpacing}
  ${layout}
`;

Heading.defaultProps = {
  fontFamily: 'title',
  fontWeight: 'bold',
  lineHeight: 'compact',
  display: 'block',
};

Heading.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
  ...layout.propTypes,
};

export const H1 = styled(Heading)``;
H1.defaultProps = {
  as: 'h1', fontSize: 5, fontWeight: 'bold', lineHeight: 'compact', letterSpacing: '-0.02em',
};

export const H2 = styled(Heading)``;
H2.defaultProps = {
  as: 'h2', fontSize: 4, fontWeight: 'bold', lineHeight: 'compact', letterSpacing: '-0.02em',
};

export const H3 = styled(Heading)``;
H3.defaultProps = {
  as: 'h3', fontSize: 3, fontWeight: 'bold', lineHeight: 'compact', letterSpacing: '-0.02em',
};

export const H4 = styled(Heading)``;
H4.defaultProps = {
  as: 'h4', fontSize: 2, fontFamily: 'body', fontWeight: 'bold', color: 'primary', uppercase: true, lineHeight: 'display',
};

export const H5 = styled(Heading)``;
H5.defaultProps = {
  as: 'h5', fontSize: 2, fontFamily: 'body', fontWeight: 'bold', uppercase: true, lineHeight: 'display',
};

export const H6 = styled(Heading)``;
H6.defaultProps = {
  as: 'h6', fontSize: 1, fontFamily: 'body', fontWeight: 'bold', uppercase: true, lineHeight: 'compact',
};

export const H7 = styled(Heading)``;
H7.defaultProps = {
  as: 'h6', fontSize: 1, fontFamily: 'body', fontWeight: 'bold', uppercase: true, lineHeight: 'display', color: 'base40',
};


const LargeTitle = styled(H1)`
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    display: none;
  }
`;

const SmallTitle = styled(H2)`
  display: none;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    display: initial;
  }
`;

export const CombinedHeading = ({
  subtitle, subtitleColor, title, titleColor, ...rest
}) => (
  <Box {...rest}>
    <H4 color={subtitleColor}>{subtitle}</H4>
    <SmallTitle color={titleColor}>{title}</SmallTitle>
    <LargeTitle color={titleColor}>{title}</LargeTitle>
  </Box>
);

CombinedHeading.defaultProps = {
  subtitleColor: 'primary',
  titleColor: 'white',
};

CombinedHeading.propTypes = {
  subtitle: PropTypes.string.isRequired,
  subtitleColor: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  titleColor: PropTypes.string,
};


export default Heading;
