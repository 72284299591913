
import styled from 'styled-components';
import { H1 } from '../../Heading';

const Title = styled(H1)``;

Title.defaultProps = {
  color: 'white',
};

export default Title;
