import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box from './Box';


const ShowMoreButton = styled(Box)`
  cursor: pointer;
  color: ${(p) => themeGet(`colors.${p.buttonColor}`, p.buttonColor)(p)};
  text-transform: uppercase;
  font-size: ${(p) => p.theme.fontSizes[1]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  margin-top: ${(p) => p.theme.space[6]};
  &:hover {
    text-decoration: underline;
  }
  display: none;
  @media (max-width: ${(p) => p.theme.breakpoints[p.breakpointIndex]}) {
    display: initial;
  }
`;

const ShowMoreContent = styled(Box)`
  @media (max-width: ${(p) => p.theme.breakpoints[p.breakpointIndex]}) {
    display: ${(p) => (!p.show ? 'none' : 'initial')};
  }
`;

const ShowMore = ({ buttonColor, breakpointIndex, children }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <ShowMoreContent breakpointIndex={breakpointIndex} show={show}>{children}</ShowMoreContent>
      <ShowMoreButton breakpointIndex={breakpointIndex} buttonColor={buttonColor} onClick={() => setShow(!show)}>{ show ? '...Less' : '...More' }</ShowMoreButton>
    </>
  );
};

ShowMore.defaultProps = {
  breakpointIndex: 2,
  buttonColor: 'white',
};

ShowMore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  breakpointIndex: PropTypes.number,
  buttonColor: PropTypes.string,
};

export default ShowMore;
