import styled from 'styled-components';
import { space, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';

const borders = ({ color, theme }) => {
  const borderColor = color ? theme.colors[color] : theme.colors.base20;
  const focusColor = color ? theme.colors[color] : theme.colors.base40;
  return {
    'border-color': borderColor,
    'box-shadow': `0 0 0 1px ${borderColor}`,
    ':focus': {
      outline: 0,
      'border-color': focusColor,
      'box-shadow': `0 0 0 2px ${focusColor}`,
    },
  };
};

const TextArea = styled.textarea`
  box-sizing: border-box;
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: ${themeGet('fontSizes.1')};
  color: inherit;
  background-color: transparent;
  border-radius: ${themeGet('radii.2')};
  border-width: 0px;
  border-style: solid;
  border-color: ${themeGet('colors.base20')};

  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 12px;
  padding-right: 12px;

  margin: 0;

  ::placeholder {
    color: ${themeGet('colors.base40')};
  }

  ${borders};
  ${space};
  ${layout};
`;

TextArea.displayName = 'TextArea';
TextArea.isField = true;
TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  ...borders.propTypes,
  ...space.propTypes,
  ...layout.propTypes,
};

export default TextArea;
