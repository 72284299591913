import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  position, top, right, bottom, left, zIndex,
} from 'styled-system';
import Flex from '../Flex';
import Icon from '../Icon';

const Container = styled(Flex)`
  ${position}
  ${top}
  ${bottom}
  ${left}
  ${right}
  ${zIndex}
`;

Container.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 301,
  backgroundColor: 'white',
};

const SectionLoading = ({
  icon, size, spin, stepping, iconColor, children, ...rest
}) => (
  <Container {...rest}>
    {children}
    <Icon color={iconColor} size={size} name={icon} stepping={stepping} spin={spin} />
  </Container>
);

SectionLoading.propTypes = {
  ...position.propTypes,
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...zIndex.propTypes,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  size: PropTypes.string,
  spin: PropTypes.bool,
  stepping: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SectionLoading.defaultProps = {
  icon: 'Spinner',
  iconColor: 'base',
  size: '50',
  spin: false,
  stepping: true,
  children: null,
};

export default SectionLoading;
