import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from './Flex';

const mediaQueries = (breakpoints, theme) => {
  const breakpointIndexes = breakpoints || [0, 1, 2, 3];
  const breakpointList = breakpointIndexes.map((b) => `(min-width: ${theme.breakpointsMin[b]}) and (max-width: ${theme.breakpointsMax[b]})`);
  return breakpointList.join(', ');
};

const ShowOn = ({ showOn, theme }) => {
  if (showOn) {
    return `
      display: none;
      @media ${mediaQueries(showOn, theme)} {
        display: flex;
      }
    `;
  }
  return '';
};

const HideOn = ({ hideOn, theme }) => {
  if (hideOn) {
    return `
      display: flex;
      @media ${mediaQueries(hideOn, theme)} {
        display: none;
      }
    `;
  }
  return '';
};

const Column = styled(Flex)`
  &:last-child {
    margin-right: 0;
  }
  flex: ${(p) => p.colspan};
  ${ShowOn}
  ${HideOn}
`;

Column.defaultProps = {
  mb: [4, 5, 6],
  colspan: 1,
  flexDirection: 'column',
  showOn: null,
  hideOn: null,
};

Column.propTypes = {
  colspan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showOn: PropTypes.arrayOf(PropTypes.number),
  hideOn: PropTypes.arrayOf(PropTypes.number),
};

export default Column;
