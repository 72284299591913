import styled from 'styled-components';
import Flex from '../../Flex';

const Body = styled(Flex)`
`;

Body.defaultProps = {
  p: [4, 5, 6, 7],
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
};

export default Body;
