import React from 'react';
import styled from 'styled-components';
import { space, fontSize } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Icon from '../Icon';
import Flex from '../Flex';

const ClickableIcon = styled(Icon)`
  pointer-events: none;
  width: 12px;
`;

const Select = styled.select`
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: ${themeGet('radii.2')};
  border-width: 1px;
  border-style: solid;
  border-color: ${themeGet('colors.base20')};
  ${space} ${fontSize} &:focus {
    outline: none;
    border-color: ${themeGet('colors.primary')};
  }
`;

Select.defaultProps = {
  fontSize: 1,
  m: 0,
  pl: 12,
  pr: 26,
  py: 14,
};

Select.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
};

const Dropdown = styled((props) => (
  <Flex alignItems="center" width="100%">
    <Select {...props} />
    <ClickableIcon ml={-20} name="NavDown" color="primary" />
  </Flex>
))``;

Dropdown.isField = true;

export default Dropdown;
