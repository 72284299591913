import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import RadioWrap from './components/RadioWrap';

const RadioInput = styled.input`
  appearance: none;
  opacity: 0;
  position: absolute;
  z-index: 0;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  &:focus {
    box-shadow: none;
  }
  &:checked ~ svg {
    color: ${(p) => p.theme.colors.blue};
  }
  &:disabled ~ svg {
    color: ${(p) => p.theme.colors.borderGray};
  }
`;

const Radio = ({
  onChange, value, checked, disabled, label, ...rest
}) => {
  const radioIconName = checked ? 'SelectedCircle' : 'EmptyCircle';

  return (
    <RadioWrap disabled={disabled} {...rest}>
      <RadioInput type="radio" onChange={onChange} value={value} checked={checked} disabled={disabled} />
      <Icon name={radioIconName} size={6} mr="3" />
      {label}
    </RadioWrap>
  );
};

Radio.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  ...space.propTypes,
};

Radio.defaultProps = {
  onChange: () => {},
  checked: false,
  disabled: false,
};

export default Radio;
