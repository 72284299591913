import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CircleIcon from '../CircleIcon';
import Box from '../Box';
import Flex from '../Flex';
import Icon from '../Icon';
import useOnClickOutside from '../../utils/useOnClickOutside';

const Wrapper = styled(Box)`
  position: ${(p) => p.position};
  left: ${(p) => p.left};
  right: 0;
  top: 0;
  bottom: ${(p) => (p.showMenu ? '0' : null)};
`;

const ControlWrapper = styled(Flex)`
  border-bottom: ${(p) => (p.showMenu ? '1px solid rgba(0,0,0,0.07)' : null)};
`;

const InputWrapper = styled(Flex)`
  height: 105px;
  width: 100%;
  max-width: 1100px;
  
  @media (max-width: ${(p) => p.theme.breakpoints[2]}) {
    height: 85px;
  }
  
  @media (max-width: ${(p) => p.theme.breakpoints[4]}) {
    height: 85px;
  }
`;

const Menu = styled(Flex)`
  background-color: ${(p) => p.theme.colors.white};
  z-index: 1;
`;

const MenuWrapper = styled(Flex)`
  height: 100%;
  position: relative;
  width: 100%;
`;

const OverLay = styled(Box)`
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

const StyledInput = styled.input`
  appearance: none;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: ${(p) => p.theme.fontSizes[1]};
  font-weight: ${(p) => p.theme.fontWeights.light};
  margin: 0;
  padding: 14px 12px;
  width: 100%;

  ::placeholder {
    color: ${(p) => p.theme.colors.base40};
  }
`;

const SearchBar = ({
  children, left, onChange, placeHolder, top, position, ...rest
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const inputRef = useRef();
  const searchBarRef = useRef();

  useOnClickOutside(searchBarRef, () => setShowMenu(false));

  useEffect(() => {
    onChange(searchValue);
  }, [onChange, searchValue]);

  return (
    <Wrapper showMenu={showMenu && !!searchValue} left={left} top={top} ref={searchBarRef} position={position}>
      <ControlWrapper alignItems="center" bg="white" flexDirection="column" onClick={() => setShowMenu(true)} showMenu={showMenu && !!searchValue} width="100%">
        <InputWrapper alignItems="center" flexDirection="row" {...rest}>
          {searchValue
            ? <CircleIcon bg="base60" color="white" name="Cross" iconSize="xs" onClick={() => { setSearchValue(''); inputRef.current.focus(); }} />
            : <Icon color="baseOpacity60" name="Search" size="24px" />}
          <StyledInput onChange={(evt) => setSearchValue(evt.currentTarget.value)} ref={inputRef} value={searchValue} placeholder={placeHolder} />
        </InputWrapper>
      </ControlWrapper>
      {showMenu && children ? (
        <MenuWrapper flexDirection="column">
          <Menu flexDirection="column">
            {children}
          </Menu>
          <OverLay
            bg="baseOpacity60"
            onClick={() => setShowMenu(false)}
          />
        </MenuWrapper>
      ) : null}
    </Wrapper>
  );
};

SearchBar.defaultProps = {
  children: null,
  left: '0',
  p: [5, null, '32px 70px', '32px 80px', '32px 0px'],
  placeHolder: 'Search for a matter',
  top: '0',
  position: 'absolute',
};

SearchBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  left: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  p: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  placeHolder: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  position: PropTypes.string,
};

SearchBar.Menu = Menu;

export default SearchBar;
