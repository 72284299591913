import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import themeGet from '@styled-system/theme-get';
import Box from '../../Box';

const Bar = styled(Box)`
  bottom: -${(p) => themeGet(`space.${p.height}`, p.height)(p)};
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  background-color: ${(p) => themeGet(`colors.${p.bg}`, p.bg)(p)};
  height: ${(p) => themeGet(`space.${p.height}`, p.height)(p)};
  width: ${(p) => p.progress};
`;

const Progress = ({
  bg, height, progress,
}) => (
  <Bar bg={bg} height={height} progress={progress} />
);

export default Progress;


Progress.defaultProps = {
  bg: 'tertiary',
  height: '12px',
};

Progress.propTypes = {
  bg: PropTypes.string,
  height: PropTypes.string,
  progress: PropTypes.string.isRequired,
  ...Box.propTypes,
};
