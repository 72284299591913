import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../../Box';

export const NoScrollBodyStyle = createGlobalStyle`
body {
  overflow: hidden;
}
`;

const Container = styled(Box)`
  position: fixed;
  z-index: 300;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 10px;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    padding: 0;
  }
`;

Container.defaultProps = {
  backgroundColor: 'baseOpacity60',
};

Container.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
};

export default Container;
