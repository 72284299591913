/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Absolute from './Absolute';
import Relative from './Relative';
import Flex from './Flex';
import Icon from './Icon';
import IconSection from './IconSection';

const StyledIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
`;

const ApplicationErrorWrapper = styled(Absolute)`
    position: fixed;
    top: 0;
    right: 0;
    max-width: 400px;
    margin: 0 ${(p) => p.theme.space[6]};
    z-index: 301;

    @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
      max-width: ${(p) => p.theme.breakpoints[1]};
      margin: 0;
      left: ${(p) => p.theme.space[3]};
      right: ${(p) => p.theme.space[3]};
    }
`;

const ToastNotification = ({ notification, close, autoCloseDuration }) => {
  const bg = notification.type === 'success' ? 'successLight' : 'errorLight';
  const iconColor = notification.type === 'success' ? 'success' : 'error';
  const icon = notification.type === 'success' ? 'CheckCircle' : 'WarningCircle';
  const timout = autoCloseDuration || 10000;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoCloseDuration) close(notification.id);
    }, 1000 * timout);
    return () => clearTimeout(timer);
  }, [close, timout]);

  return (
    <Flex
      flexDirection="column"
      mt="5"
      p="6"
      backgroundColor={bg}
      alignItems="flex-end"
      zIndex="101"
    >
      <Relative top="-16px">
        <StyledIcon
          onClick={() => close(notification.id)}
          size="12px"
          color="base"
          name="Cross"
        />
      </Relative>
      <IconSection
        width="100%"
        iconColor={iconColor}
        titleColor="base"
        iconName={icon}
        title={notification.message}
      />
    </Flex>
  );
};

ToastNotification.defaultProps = {
  autoCloseDuration: null,
};

ToastNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.oneOf(['error', 'success']),
  }).isRequired,
  close: PropTypes.func.isRequired,
  autoCloseDuration: PropTypes.number,
};

const ToastNotifications = ({ notifications, close, autoCloseDuration }) => {
  if (notifications.length === 0) return null;

  return (
    <ApplicationErrorWrapper>
      {notifications.map((n) => <ToastNotification key={n.id} notification={n} close={close} autoCloseDuration={autoCloseDuration} />)}
    </ApplicationErrorWrapper>
  );
};

ToastNotifications.defaultProps = {
  notifications: [],
  autoCloseDuration: null,
};

ToastNotifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
      type: PropTypes.oneOf(['error', 'success']),
    }),
  ),
  close: PropTypes.func.isRequired,
  autoCloseDuration: PropTypes.number,
};

export default ToastNotifications;
