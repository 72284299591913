import styled from 'styled-components';
import {
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
  fontSize,
} from 'styled-system';

const Table = styled.table`
  border-collapse: separate;
  box-sizing: border-box;
  border-spacing: 0;
  ${space}
  ${width}
  ${textAlign}
  ${border}
  ${boxShadow}
`;

Table.displayName = 'Table';

Table.defaultProps = {
  bg: 'white',
  fontSize: 2,
  width: '100%',
};

Table.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
  ...fontSize.propTypes,
};

export default Table;
