import styled from 'styled-components';
import { space } from 'styled-system';
import { H2 } from '../../Heading';

const Title = styled(H2)``;

Title.defaultProps = {
  m: 0,
  mb: [4, 5, 6, 7],
};

Title.propTypes = {
  ...space.propTypes,
};

export default Title;
