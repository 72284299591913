import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from '../Box';
import Title from './components/Title';
import Description from './components/Description';

const Triangle = styled.span`
  width: 100%;
  background-color: ${(p) => p.theme.colors[p.bg]};
  transform: skewY(-7deg);
  transform-origin: 0%;
  height: 110px;

  @media (min-width: ${(p) => p.theme.breakpoints[1]}) {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;  
  flex-direction: column;
  align-items: center;
`;

const Panel = styled(Box)`
  z-index: 100;
  display: flex;
  background-color: ${(p) => p.theme.colors[p.bg]};
  box-shadow: 0px 0px 24px rgba(7, 36, 56, 0.05);
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: unset;
  padding: ${(p) => `${p.theme.space[10]} ${p.theme.space[10]} 0`};

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    padding: ${(p) => p.theme.space[7]};
  }

  @media (min-width: ${(p) => p.theme.breakpoints[1]}) {
    border-radius: 10px;
    max-width: ${(p) => p.maxWidth || p.theme.threshold.sectionMaxWidth};
    padding: ${(p) => `${p.theme.space[10]}`};
  }
`;

Panel.defaultProps = {
  maxWidth: null,
};

const Header = ({ bg, children, ...rest }) => (
  <Wrapper>
    <Panel
      bg={bg}
      {...rest}
    >
      {children}
    </Panel>
    <Triangle bg={bg} />
  </Wrapper>
);

Header.Title = Title;
Header.Description = Description;

Header.defaultProps = {
  bg: 'base',
  children: null,
};

Header.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Header;
