import styled from 'styled-components';
import {
  space,
  width,
  border,
  boxShadow,
} from 'styled-system';

const HR = styled.hr`
  box-sizing: border-box;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  ${space}
  ${width}
  ${border}
  ${boxShadow}
`;

HR.displayName = 'hr';

HR.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...border.propTypes,
};

export default HR;
