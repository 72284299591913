import styled from 'styled-components';
import Text from '../Text';

const P = styled(Text)``;

P.defaultProps = {
  as: 'p',
  fontSize: 1,
  mb: 4,
  mt: 0,
};

export default P;
