import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Flex from '../Flex';
import ArrowBox from '../ArrowBox';

const contentArrowColor = (p) => {
  const { bg, position } = p;
  const color = themeGet(`colors.${bg}`, bg)(p);
  const bottom = { 'border-color': `transparent transparent ${color} transparent` };
  const right = { 'border-color': `transparent ${color} transparent transparent` };
  const left = { 'border-color': `transparent transparent transparent ${color}` };
  const top = { 'border-color': `${color} transparent transparent transparent` };
  if (position === 'top') return top;
  if (position === 'left') return left;
  if (position === 'right') return right;
  return bottom;
};

const popoverArrowPosition = ({ position, arrowPosition }) => {
  const bottom = {
    bottom: '100%',
    left: (arrowPosition || '50%'),
    'margin-left': '-5px',
  };
  const right = {
    top: (arrowPosition || '50%'),
    right: '100%',
    'margin-top': '-5px',
  };
  const left = {
    top: (arrowPosition || '50%'),
    left: '100%',
    'margin-top': '-5px',
  };
  const top = {
    top: '100%',
    left: (arrowPosition || '50%'),
    'margin-left': '-5px',
  };
  if (position === 'top') return top;
  if (position === 'left') return left;
  if (position === 'right') return right;
  return bottom;
};

const popoverPosition = ({ position, offsetPosition }) => {
  const bottom = {
    top: '100%',
    ...offsetPosition,
  };
  const top = {
    bottom: '100%',
    ...offsetPosition,
  };
  const left = {
    right: '100%',
    top: 0,
    ...offsetPosition,
  };
  const right = {
    left: '100%',
    top: 0,
    ...offsetPosition,
  };
  if (position === 'top') return top;
  if (position === 'left') return left;
  if (position === 'right') return right;
  return bottom;
};

const PopoverWrapper = styled(Flex)`
  position: relative;
  background-color: transparent;
  cursor: ${(p) => p.cursor};

  ${ArrowBox} {
    background-color: ${(p) => themeGet(`colors.${p.bg}`, p.bg)(p)};
    margin-top: initial;
    position: absolute;
    z-index: 1;
    ${popoverPosition}
    margin-bottom: ${(p) => (p.position === 'top' ? p.contentMargin : 0)};
    margin-top: ${(p) => (p.position === 'bottom' ? p.contentMargin : 0)};
    margin-left: ${(p) => (p.position === 'right' ? p.contentMargin : 0)};
    margin-right: ${(p) => (p.position === 'left' ? p.contentMargin : 0)};
    
    &::after {
      bottom: initial;
      left: initial;
      margin-left: initial;
      ${popoverArrowPosition}
      ${contentArrowColor}
    }
    ${(p) => (p.show ? 'visibility: visible' : null)};
  }

  &:hover ${ArrowBox} {
    ${(p) => (p.showOnHover ? 'visibility: visible' : null)};
  }
`;

export const popoverDefaultProps = {
  show: null,
  showOnHover: true,
  position: 'bottom',
  arrowPosition: null,
  offsetPosition: null,
  contentMargin: '6px',
  bg: 'white',
  cursor: 'default',
};

export const popoverPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  show: PropTypes.bool,
  showOnHover: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  arrowPosition: PropTypes.string,
  offsetPosition: PropTypes.object,
  contentMargin: PropTypes.string,
  cursor: PropTypes.string,
};

const Popover = ({ children, ...props }) => (
  <PopoverWrapper {...props}>{children}</PopoverWrapper>
);

Popover.defaultProps = {
  ...popoverDefaultProps,
};

Popover.propTypes = {
  ...popoverPropTypes,
};

Popover.Content = ArrowBox;

export default Popover;
