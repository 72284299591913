import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircleIcon from '../CircleIcon';
import Flex from '../Flex';
import Text from '../Text';
import useScrollToTop from '../../utils/useScrollToTop';

const NavWrapper = styled(Flex)`
  border-radius: 32px;
  width: 100%;
  max-width: 300px;
  background-color: transparent;
`;

const NavContainer = styled(Flex)`
  user-select: none;
  width: 100%;
  justify-content: space-between;
  cursor: default;
  align-items: center;
  border-radius: 32px;
  height: 46px;
  background-color: ${(p) => (p.disabled ? p.theme.colors.white : p.theme.colors.secondary)};
  color: ${(p) => (p.disabled ? p.theme.colors.base60 : p.theme.colors.white)};
  ${Text} {
    user-select: none;
    text-transform: uppercase;
    letter-spacing: ${(p) => p.theme.letterSpacings.button};
    font-size: ${(p) => p.theme.fontSizes[0]};
    font-weight: ${(p) => p.theme.fontWeights.medium}
  }
  box-shadow: 0px 14px 20px rgba(7,36,56,0.1);
`;

const PageButton = ({
  actionComplete, totalPages, currentPage, nextPageClick, previousPageClick, fullWidthBreakpointIndex, disabled, width,
}) => {
  const lastPageHit = actionComplete || (currentPage > 0 && totalPages > 0 && currentPage === totalPages);
  const { setScrollToTop } = useScrollToTop(false);
  if (totalPages === 0) return null;
  const navBg = disabled ? 'base' : 'white';
  const navColor = disabled ? 'white' : 'secondary';
  const previousPageAction = () => {
    if (currentPage === 1) return;
    previousPageClick();
  };
  const nextPageAction = () => {
    if (disabled || lastPageHit) return;
    setScrollToTop(true);
    nextPageClick();
  };

  return (
    <NavWrapper fullWidthBreakpointIndex={fullWidthBreakpointIndex}>
      <NavContainer disabled={disabled} width={width}>
        <CircleIcon disabled={disabled || currentPage === 1} ml="4" onClick={() => previousPageAction()} iconSize="xs" bg={navBg} color={navColor} name="NavLeft" />
        <Text textAlign="center" mx="6">{`Read all pages ${currentPage}/${totalPages}`}</Text>
        <CircleIcon disabled={disabled || lastPageHit} mr="4" onClick={() => nextPageAction()} iconSize="xs" bg={navBg} color={navColor} name="NavRight" />
      </NavContainer>
    </NavWrapper>
  );
};

PageButton.defaultProps = {
  actionComplete: false,
  totalPages: null,
  currentPage: null,
  fullWidthBreakpointIndex: 2,
  disabled: false,
  width: '100%',
};

PageButton.propTypes = {
  actionComplete: PropTypes.bool,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  nextPageClick: PropTypes.func.isRequired,
  previousPageClick: PropTypes.func.isRequired,
  fullWidthBreakpointIndex: PropTypes.number,
  disabled: PropTypes.bool,
  width: PropTypes.string,
};

export default PageButton;
