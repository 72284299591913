import styled from 'styled-components';
import {
  space, fontSize, fontWeight, color,
} from 'styled-system';
import LI from './LI';

const inline = props => (props.inline
  ? `
    padding-left: 0;
    > ${LI} {
      display: inline;
    }
  `
  : null);

const nobullets = props => (props.nobullets
  ? `
      padding-left: 0;
      list-style-type: none;
    `
  : null);

const UL = styled.ul`
  padding-left: 30px;
  ${fontSize}
  ${space}
  ${inline}
  ${nobullets}
`;

UL.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...fontWeight.propTypes,
};

UL.defaultProps = {
  fontSize: 2,
  color: 'base',
};

export default UL;
