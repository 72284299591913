import styled from 'styled-components';
import {
  position, top, right, bottom, left, zIndex,
} from 'styled-system';
import Box from './Box';

const Fixed = styled(Box)`
  ${position}
  ${top}
  ${bottom}
  ${left}
  ${right}
  ${zIndex}
`;

Fixed.defaultProps = {
  position: 'fixed',
};

Fixed.propTypes = {
  ...position.propTypes,
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
  ...zIndex.propTypes,
};

Fixed.displayName = 'Fixed';

export default Fixed;
