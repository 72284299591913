import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Flex from '../../Flex';

const StyledFlex = styled(Flex)`
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${(p) => p.theme.space[5]};
`;

export default function Header({ children }) {
  return (<StyledFlex>{children}</StyledFlex>);
}
Header.defaultProps = {
  children: null,
};
Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
