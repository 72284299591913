/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Absolute from './Absolute';
import Relative from './Relative';
import Flex from './Flex';
import Icon from './Icon';
import IconSection from './IconSection';

const StyledIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
`;

const ApplicationErrorWrapper = styled(Absolute)`
    position: fixed;
    top: 0;
    right: 0;
    max-width: 400px;
    margin: 0 ${(p) => p.theme.space[6]};
    z-index: 301;

    @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
      max-width: ${(p) => p.theme.breakpoints[1]};
      margin: 0;
      left: ${(p) => p.theme.space[3]};
      right: ${(p) => p.theme.space[3]};
    }
`;

const ApplicationError = ({ errors, close }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('ApplicationError is deprecated: please use ToastNotifications instead.');
    }
  }, []);

  const errorKeys = Object.keys(errors);
  if (errorKeys.length === 0) return null;

  return (
    <ApplicationErrorWrapper>
      {errorKeys.map((id) => (
        <Flex
          key={id}
          flexDirection="column"
          mt="5"
          p="6"
          backgroundColor="errorLight"
          alignItems="flex-end"
          zIndex="101"
        >
          <Relative top="-16px"><StyledIcon onClick={() => close(id)} size="14px" color="error" name="Cross" /></Relative>
          <IconSection
            width="100%"
            iconColor="error"
            titleColor="error"
            iconName="WarningCircle"
            title={errors[id]}
          />
        </Flex>
      )) }
    </ApplicationErrorWrapper>
  );
};

ApplicationError.defaultProps = {
  errors: {},
};

ApplicationError.propTypes = {
  errors: PropTypes.object,
  close: PropTypes.func.isRequired,
};

export default ApplicationError;
