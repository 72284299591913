import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';

const DL = styled.dl`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    flex-direction: column;
  }
  ${space}
  ${width}
  ${color}
  ${textAlign}
  ${border}
  ${boxShadow}
`;

DL.displayName = 'Datalist';

DL.defaultProps = {
  m: 0,
  mb: 4,
};

DL.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
  striped: PropTypes.bool,
};

export default DL;
