import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { themeOverrides, themeOverrideProps } from './themeOverrides';
import liveSignThemeOverrides from './liveSignThemeOverrides';
import eCAFThemeOverrides from './eCAFThemeOverrides';
import { override } from './util';
import mediaQueriesFactory from './mediaQueries';

const breakpointsMin = ['0px', '577px', '769px', '993px', '1439px'];
const breakpoints = ['576px', '768px', '992px', '1200px', '1440px'];
const space = ['0', '2px', '4px', '8px', '12px', '16px', '24px', '32px', '48px', '64px', '72px', '128px', '256px'];
const sizes = ['0', '2px', '4px', '8px', '12px', '16px', '20px', '24px', '32px', '64px', '128px', '256px'];
const fonts = { body: '\'Rubik\', sans-serif', title: '\'Zilla Slab\', serif' };
const fontSizes = ['12px', '14px', '16px', '21px', '28px', '36px', '48px', '58px'];
const fontWeights = { light: 300, medium: 500, bold: 600 };
const lineHeights = { standard: 1.5, display: 1.25, compact: 1 };
const letterSpacings = {
  normal: 'normal', small: '0.02em', button: '0.06em', caps: '0.1em',
};
const radii = ['0', '2px', '4px', '8px', '16px', '32px', '64px'];
const shadows = {
  none: 'none',
  sm: '0 0 2px 0 rgba(0,0,0,.08),0 1px 4px 0 rgba(0,0,0,.16)',
  md: '0px 0px 24px rgba(7, 36, 56, 0.05)',
  lg: '0px 14px 34px rgba(7, 36, 56, 0.1)',
  xl: '0px 14px 54px rgba(0, 0, 0, 0.25)',
};

const baseTheme = {
  breakpointsMin,
  breakpointsMax: breakpoints,
  breakpoints,
  colors: {}, // populated by overrides
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  name: 'baseTheme',
  radii,
  space,
  threshold: {
    sectionMaxWidth: '1200px',
    contentMaxWidth: '760px',
  },
  sizes,
  shadows,
};

const baseThemeProps = PropTypes.shape({
  breakpointsMin: PropTypes.arrayOf(PropTypes.string).isRequired,
  breakpointsMax: PropTypes.arrayOf(PropTypes.string).isRequired,
  breakpoints: PropTypes.arrayOf(PropTypes.string).isRequired,
  space: PropTypes.arrayOf(PropTypes.string).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fonts: PropTypes.shape({ body: PropTypes.string, title: PropTypes.string }),
  fontSizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fontWeights: PropTypes.shape({ light: PropTypes.number, medium: PropTypes.number, bold: PropTypes.number }).isRequired,
  lineHeights: PropTypes.shape({ standard: PropTypes.number, display: PropTypes.number }).isRequired,
  letterSpacings: PropTypes.shape({ standard: PropTypes.string, display: PropTypes.string }).isRequired,
  colors: PropTypes.shape({
    ...themeOverrideProps.colors,
    white: PropTypes.string,
    black: PropTypes.string,
    text: PropTypes.string,
    bg: PropTypes.string,
    muted: PropTypes.string,
    hover: PropTypes.string,
  }),
  radii: PropTypes.arrayOf(PropTypes.string).isRequired,
  shadows: PropTypes.shape({
    none: PropTypes.string, sm: PropTypes.string, md: PropTypes.string, lg: PropTypes.string,
  }).isRequired,
});

const theme = override(baseTheme, themeOverrides);

const mediaQueries = mediaQueriesFactory(css, theme);

export {
  eCAFThemeOverrides,
  liveSignThemeOverrides,
  override,
  themeOverrides,
  themeOverrideProps,
  baseThemeProps,
  mediaQueries,
};

export default theme;
