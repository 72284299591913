import styled from 'styled-components';
import {
  flexBasis,
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  layout,
} from 'styled-system';

import Box from '../Box';

const themed = (key) => (props) => props.theme[key];

const Flex = styled(Box)({
  display: 'flex',
},
flex,
flexBasis,
flexWrap,
flexDirection,
alignItems,
justifyContent,
layout,
themed('Flex'));

flexDirection.defaultProps = {
  flexWrap: 'wrap',
};

Flex.propTypes = {
  ...flex.propTypes,
  ...flexBasis.propTypes,
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...layout.propTypes,
};

export default Flex;
