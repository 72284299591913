import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Icon from '../../Icon';
import Relative from '../../Relative';

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 0;
  cursor: pointer;
  &:hover {
    color: ${p => themeGet(`colors.${p.buttonHoverColor}`)};
  }
`;

const Close = ({
  onClose, buttonSize, buttonColor, buttonHoverColor,
}) => (
  <Relative width="100%" textAlign="right">
    <StyledIcon m={[4, 5, 6, 7]} size={buttonSize} color={buttonColor} name="Cross" onClick={onClose} buttonHoverColor={buttonHoverColor} />
  </Relative>
);

Close.defaultProps = {
  buttonSize: '16px',
  buttonColor: 'base60',
  buttonHoverColor: 'base80',
};

Close.propTypes = {
  onClose: PropTypes.func.isRequired,
  buttonSize: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
};

export default Close;
