import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from './Box';

const mediaQueries = (p) => {
  const breakpointIndexes = p.breakpoints || [0, 1, 2, 3];
  const breakpointList = breakpointIndexes.map((b) => `(min-width: ${p.theme.breakpointsMin[b]}) and (max-width: ${p.theme.breakpointsMax[b]})`);
  return breakpointList.join(', ');
};

export const Show = styled(Box)`
  display: none;
  @media ${(p) => mediaQueries(p)} {
    display: initial;
  }
`;

export const Hide = styled(Box)`
  display: initial;
  @media ${(p) => mediaQueries(p)} {
    display: none;
  }
`;

Show.propTypes = {
  breakpoints: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Hide.propTypes = {
  breakpoints: PropTypes.arrayOf(PropTypes.number).isRequired,
};
