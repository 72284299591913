import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Flex from '../../Flex';

const Wrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const ActionContainer = ({ children }) => (
  <Wrapper alignItems="center" flexDirection="row" p={4} justifyContent="flex-end">
    {children}
  </Wrapper>
);

export default ActionContainer;

Wrapper.defaultProps = {
};

ActionContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
