import styled from 'styled-components';
import { typography } from 'styled-system';

const CheckBoxWrapper = styled.label`
display: inline-flex;
justify-content: flex-start;
align-items: flex-start;
flex-wrap: nowrap;
font-size: ${(props) => props.theme.fontSizes[1]};
cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
min-height: ${(props) => props.minHeight || 'initial'};
min-width: ${(props) => props.minWidth || 'initial'};
user-select: none;
margin-top: ${(props) => (props.mt && props.theme.space[props.mt]) || 0};
margin-right: ${(props) => (props.mr && props.theme.space[props.mr]) || 0};
margin-bottom: ${(props) => (props.mb && props.theme.space[props.mb]) || 0};
margin-left: ${(props) => (props.ml && props.theme.space[props.ml]) || 0};
${typography}

svg[data-name='checked'] {
  display: none;
}

> input:checked {
  & ~ svg[data-name='checked'] {
    display: inline-block;
  }

  & ~ svg[data-name='empty'] {
    display: none;
  }
}
`;

CheckBoxWrapper.defaultProps = {
  mb: 3,
  mr: 6,
  disabled: false,
  minHeight: '22px',
  minWidth: null,
  ...typography.defaultProps,
};

CheckBoxWrapper.propTypes = {
  ...typography.propTypes,
};

export default CheckBoxWrapper;
