import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popover from '../Popover';
import Text from '../Text';
import Flex from '../Flex';
import Relative from '../Relative';
import Absolute from '../Absolute';

const shakeMe = (p) => {
  if (p.shake) {
    return `
      animation: shake 0.2s;
      animation-iteration-count: 4;
    `;
  }
  return null;
};

const GotIt = styled(Text)`
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.colors.secondaryDark};
  }

  ${shakeMe}

  @keyframes shake {
    0% { transform: translate(0px, 0px); }
    10% { transform: translate(-1px, 0); }
    20% { transform: translate(-2px, 0); }
    30% { transform: translate(-3px, 0); }
    40% { transform: translate(-2px, 0); }
    50% { transform: translate(-1px, 0); }
    60% { transform: translate(0px, 0); }
    70% { transform: translate(1px, 0); }
    80% { transform: translate(2px, 0); }
    90% { transform: translate(3px, 0); }
    100% { transform: translate(2px, 0); }
    100% { transform: translate(1px, 0); }
  }
`;

GotIt.defaultProps = {
  mt: 5,
  uppercase: true,
  color: 'secondary',
  sm: true,
};

const ConfirmAction = ({
  message, width, onConfirm, dismissText, children,
}) => {
  const [show, setShow] = useState(true);
  const [invalidClick, setInvalidClick] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInvalidClick(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [invalidClick]);

  const captureClick = () => {
    if (invalidClick) return;
    setInvalidClick(true);
  };

  const clickCapturer = show ? (
    <Relative width="0" height="0">
      <Absolute width={width} height="60px" onClick={captureClick} />
    </Relative>
  ) : null;

  const dismiss = () => {
    setShow(false);
    if (onConfirm !== null) onConfirm();
  };

  return (
    <Popover contentMargin="10px" width={width} position="top" arrowPosition="50%" bg="white" color="base" showOnHover={false} show={show}>
      {clickCapturer}
      {children}
      <Popover.Content boxShadow="xl" width={width}>
        <Flex px={5} py={4} flexDirection="column" alignItems="center" width="100%">
          {message}
          <GotIt shake={invalidClick} onClick={dismiss}>{dismissText}</GotIt>
        </Flex>
      </Popover.Content>
    </Popover>
  );
};

ConfirmAction.defaultProps = {
  onConfirm: null,
  dismissText: 'continue',
};

ConfirmAction.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  dismissText: PropTypes.string,
};

export default ConfirmAction;
