import styled from 'styled-components';
import { space } from 'styled-system';
import Flex from './Flex';

const ActionContainer = styled(Flex)``;

ActionContainer.defaultProps = {
  mt: [4, 5, 6, 7],
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  flexDirection: 'row',
};

ActionContainer.propTypes = {
  ...space.propTypes,
};

export default ActionContainer;
