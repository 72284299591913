import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  color,
  textAlign,
} from 'styled-system';
import TR from './TR';
import TD from './TD';

const TBody = styled.tbody`
  ${TR}:first-child ${TD}:first-child {
    border-top-left-radius: ${p => p.radius};
  }
  ${TR}:first-child ${TD}:last-child {
    border-top-right-radius: ${p => p.radius};
  }
  ${TR}:last-child ${TD}:first-child {
    border-bottom-left-radius: ${p => p.radius};
  }
  ${TR}:last-child ${TD}:last-child {
    border-bottom-right-radius: ${p => p.radius};
  }
  ${TR} ${TD} {
    ${color};
    border-bottom: 1px solid ${p => p.theme.colors.base10};
    ${textAlign};
  }
  ${TR}:last-child ${TD} {
    border-bottom: 1px solid transparent;
  }
  
  ${TR} ${TD.Spacer} {
    border-bottom: 1px solid transparent;
  }
`;

TBody.displayName = 'TBody';

TBody.defaultProps = {
  radius: '6px',
  textAlign: 'left',
};

TBody.propTypes = {
  radius: PropTypes.string,
  ...textAlign.propTypes,
  ...color.propTypes,
};

export default TBody;
