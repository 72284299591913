import PropTypes from 'prop-types';

export const themeOverrides = {
  colors: {
    opacity90: 'rgba(0, 0, 0, 0.90)',
    opacity80: 'rgba(0, 0, 0, 0.80)',
    opacity60: 'rgba(0, 0, 0, 0.60)',
    opacity40: 'rgba(0, 0, 0, 0.40)',
    opacity10: 'rgba(0, 0, 0, 0.10)',
    opacity07: 'rgba(0, 0, 0, 0.07)',
    opacity04: 'rgba(0, 0, 0, 0.04)',
    opacity03: 'rgba(0, 0, 0, 0.03)',

    baseOpacity90: 'rgba(7,36,56, 0.90)',
    baseOpacity80: 'rgba(7,36,56, 0.80)',
    baseOpacity60: 'rgba(7,36,56, 0.60)',
    baseOpacity40: 'rgba(7,36,56, 0.40)',
    baseOpacity10: 'rgba(7,36,56, 0.10)',
    baseOpacity07: 'rgba(7,36,56, 0.07)',
    baseOpacity04: 'rgba(7,36,56, 0.04)',
    baseOpacity03: 'rgba(0, 0, 0, 0.03)',

    baseDark: '#031019',
    base: '#072438',
    base80: '#395060',
    base60: '#6A7C88',
    base40: '#9CA7AF',
    base20: '#CDD3D7',
    base10: '#E6E9EB',
    base05: '#F3F4F5',

    primaryDark: '#0470bd',
    primary: '#047DD4',
    primary80: '#3697DD',
    primary60: '#68B1E5',
    primary40: '#9BCBEE',
    primary20: '#CDE5F6',
    primary10: '#E6F2FB',
    primary05: '#F2F9FD',

    secondaryDark: '#528e25',
    secondary: '#5A9E28',
    secondary80: '#7BB153',
    secondary60: '#9CC57E',
    secondary40: '#BDD8A9',
    secondary20: '#DEECD4',
    secondary10: '#EFF5EA',
    secondary05: '#F7FAF4',

    tertiaryDark: '#B4B40D',
    tertiary: '#C9C90E',
    tertiary80: '#D4D43E',
    tertiary60: '#DFDF6E',
    tertiary40: '#E9E99F',
    tertiary20: '#F4F4CF',
    tertiary10: '#FAFAE7',
    tertiary05: '#FCFCF3',

    success: '#0a0',
    successLight: '#ecf7ec',
    successDark: '#060',

    warn: '#f68013',
    warnLight: '#fff5eb',
    warnDark: '#f06f20',

    error: '#A81C39',
    errorLight: '#F0EBED',
    errorDark: '#691223',
  },
};

export const themeOverrideProps = {
  colors: {
    opacity90: PropTypes.string,
    opacity80: PropTypes.string,
    opacity60: PropTypes.string,
    opacity40: PropTypes.string,
    opacity10: PropTypes.string,
    opacity07: PropTypes.string,
    opacity04: PropTypes.string,
    opacity03: PropTypes.string,

    baseOpacity90: PropTypes.string,
    baseOpacity80: PropTypes.string,
    baseOpacity60: PropTypes.string,
    baseOpacity40: PropTypes.string,
    baseOpacity10: PropTypes.string,
    baseOpacity07: PropTypes.string,
    baseOpacity04: PropTypes.string,
    baseOpacity03: PropTypes.string,

    baseDark: PropTypes.string,
    base: PropTypes.string,
    base80: PropTypes.string,
    base60: PropTypes.string,
    base40: PropTypes.string,
    base20: PropTypes.string,
    base10: PropTypes.string,
    base05: PropTypes.string,

    primaryDark: PropTypes.string,
    primary: PropTypes.string,
    primary80: PropTypes.string,
    primary60: PropTypes.string,
    primary40: PropTypes.string,
    primary20: PropTypes.string,
    primary10: PropTypes.string,
    primary05: PropTypes.string,

    secondaryDark: PropTypes.string,
    secondary: PropTypes.string,
    secondary80: PropTypes.string,
    secondary60: PropTypes.string,
    secondary40: PropTypes.string,
    secondary20: PropTypes.string,
    secondary10: PropTypes.string,
    secondary05: PropTypes.string,

    tertiaryDark: PropTypes.string,
    tertiary: PropTypes.string,
    tertiary80: PropTypes.string,
    tertiary60: PropTypes.string,
    tertiary40: PropTypes.string,
    tertiary20: PropTypes.string,
    tertiary10: PropTypes.string,
    tertiary05: PropTypes.string,

    success: PropTypes.string,
    successLight: PropTypes.string,
    successDark: PropTypes.string,

    warn: PropTypes.string,
    warnLight: PropTypes.string,
    warnDark: PropTypes.string,

    error: PropTypes.string,
    errorLight: PropTypes.string,
    errorDark: PropTypes.string,
  },
};
