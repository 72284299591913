import { toString } from './utilities';

export default (config) => (value) => {
  const blacklist = config.strict ? config.blacklist : config.blacklist.map((b) => toString(b).toLowerCase());
  const compareValue = config.strict ? value : toString(value).toLowerCase();
  let isBlacklisted = false;

  blacklist.forEach((e) => {
    if (compareValue.includes(e)) isBlacklisted = true;
  });

  return isBlacklisted ? config.message : null;
};
