import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';
import TD from './TD';

const clickableStyles = (props) => {
  if (!props.clickable) return null;

  return `
    &&:hover {
      cursor: pointer;
      background-color: ${props.theme.colors.hover};
    }
    &&:hover > ${TD} {
      background-color: transparent;
    }
  `;
};

const TR = styled.tr`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${textAlign}
  ${border}
  ${boxShadow}
  ${clickableStyles}
`;

TR.displayName = 'TR';

TR.defaultProps = {
  clickable: false,
};

TR.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
  clickable: PropTypes.bool,
};

export default TR;
