import styled from 'styled-components';
import Text from '../../Text';

const Description = styled(Text)``;

Description.defaultProps = {
  color: 'white',
  fontSize: 2,
};

Description.propTypes = {
};

export default Description;
