import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from './Flex';
import { H4 } from './Heading';
import Text from './Text';

const StyledLabel = styled(H4)`
  display: inline-block;
`;

const StyledMessageBody = styled(Text)`
  flex: 1;
`;

const StyledMessageBox = styled(Flex)`
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
`;

StyledMessageBox.defaultProps = {
  bg: 'primary10',
  color: 'base',
  borderRadius: 3,
};

const MessageBox = ({
  label, children, textMargin, lineHeight, ...rest
}) => (
  <StyledMessageBox {...rest}>
    <StyledLabel lineHeight={lineHeight} fontSize={0} color="base" m="0" ml={textMargin} mr={textMargin} my={textMargin}>{label}</StyledLabel>
    <StyledMessageBody lineHeight={lineHeight} m="0" fontSize={1} my={textMargin} mr={textMargin}>{children}</StyledMessageBody>
  </StyledMessageBox>
);

MessageBox.defaultProps = {
  label: null,
  textMargin: [4, 5],
  lineHeight: 'display',
};

MessageBox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  textMargin: PropTypes.arrayOf(PropTypes.number),
  lineHeight: PropTypes.string,
};

export default MessageBox;
