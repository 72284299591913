import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';

const DT = styled.dt`
  box-sizing: border-box;
  flex-basis: 30%;
  max-width: 250px;
  font-weight: ${(p) => (p.titleLabel ? p.theme.fontWeights.bold : p.theme.fontWeights.light)};
  text-transform: ${(p) => (p.titleLabel ? 'uppercase' : 'initial')};
  font-size: ${(p) => p.theme.fontSizes[(p.titleLabel ? 1 : 2)]};
  color: ${(p) => (p.titleLabel ? p.theme.colors.primary : p.theme.colors.base)};
  white-space: wrap;
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    flex-basis: 100%;
    max-width: 100%
  }
  ${space}
  ${width}
  ${color}
  ${textAlign}
  ${border}
  ${boxShadow}
`;

DT.displayName = 'DT';

DT.defaultProps = {
  py: 3,
  pr: [0, 3],
  titleLabel: true,
};

DT.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
  titleLabel: PropTypes.bool,
};

export default DT;
