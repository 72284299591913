import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import {
  space,
  width,
  color,
  textAlign,
  verticalAlign,
  border,
  boxShadow,
} from 'styled-system';

const spacerStyle = (p) => {
  if (!p.spacer) return null;

  return `
    &&&&& { 
      border-bottom: 1px solid transparent;
      background-color: ${themeGet(`colors.${p.bg}`, p.bg)(p)};
    }
  `;
};

const TD = styled.td`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${textAlign}
  ${verticalAlign}
  ${border}
  ${boxShadow}
  ${spacerStyle}
`;

TD.displayName = 'TD';

TD.defaultProps = {
  px: 5,
  py: 5,
  verticalAlign: 'top',
  bg: 'white',
};

TD.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...verticalAlign.propTypes,
  ...border.propTypes,
  spacer: PropTypes.bool,
};

export default TD;
