import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import SortableHeader from './components/SortableHeader';
import Loader from './components/Loader';
import Flex from '../Flex';
import Column from '../Column';
import { H2 } from '../Heading';
import Box from '../Box';

const mediaQueries = (p) => {
  if (!p.responsiveHide) return '(min-width: 0) and (max-width: 0)';
  const breakpointList = p.responsiveHide.map((b) => `(min-width: ${p.theme.breakpointsMin[b]}) and (max-width: ${p.theme.breakpointsMax[b]})`);
  return breakpointList.join(', ');
};

const LoadMoreButton = styled.button`
  padding: 14px;
  background-color: #f7f7f7;
  border: 1px solid #eaeaea;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.base};;
`;

const NoResultsWrapper = styled(Flex)`
  padding: ${(p) => p.theme.space[9]} 0;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NoResultsText = styled(Box)`
  max-width: 440px;
`;

const ScrollContainer = styled(Flex)`
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.radii[3]};
  padding: ${(p) => `${p.theme.space[6]} ${p.theme.space[9]}`} ;

  @media (max-width: ${(p) => p.theme.breakpoints[3]}) {
    padding: ${(p) => `${p.theme.space[5]} ${p.theme.space[7]}`};
  }
  @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
    padding: ${(p) => `${p.theme.space[4]} ${p.theme.space[6]}`};
  }
`;

const DataList = ({
  loadMore, hasMore, displayNoResult, children, isLoading,
}) => (
  <ScrollContainer
    element={ScrollContainer}
    hasMore={hasMore}
    loader={<Loader key={0} />}
  >
    {displayNoResult && (
    <NoResultsWrapper>
      <H2 mb="7">Nothing to see here!</H2>
      <NoResultsText>Try removing any filters you might have applied, or contact us if you think you should be able to see some results here.</NoResultsText>
    </NoResultsWrapper>
    )}
    {children}
    {hasMore && <LoadMoreButton type="button" onClick={() => { if (!isLoading) loadMore(); }}>Load More</LoadMoreButton>}
  </ScrollContainer>
);

const DataListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.opacity07};
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  &:hover {
    ${(p) => (p.hoverColor ? `background-color: ${themeGet(`colors.${p.hoverColor}`, p.hoverColor)(p)}` : '')};
  }
`;

const DataListColumn = styled(Column)`
  display: flex;
  flex-direction: ${(p) => p.direction};
  flex: ${(p) => p.width};
  @media ${(p) => mediaQueries(p)} {
    display: none;
  }
  overflow: hidden;
  overflow-wrap: anywhere;
`;

DataListColumn.defaultProps = {
  direction: 'column',
  width: 1,
  responsiveHide: null,
  mb: [4, 4, 5, 7],
  mt: [4, 4, 5, 7],
  mr: [4],
};

DataListColumn.propTypes = {
  direction: PropTypes.string,
  width: PropTypes.number,
  responsiveHide: PropTypes.arrayOf(PropTypes.number),
};

DataList.Item = DataListItem;

DataList.Column = DataListColumn;

DataList.SortableHeader = SortableHeader;

DataList.defaultProps = {
  hasMore: false,
  children: null,
  displayNoResult: false,
};

DataList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  isLoading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  displayNoResult: PropTypes.bool,
};

export default DataList;
