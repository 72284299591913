import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { width, size } from 'styled-system';
import Box from '../Box';
import Flex from '../Flex';
import { H6 } from '../Heading';
import Icon from '../Icon';

const IconSectionContainer = styled(Flex)`
  ${width}
`;

const Title = styled(H6)`
  line-height: ${(p) => p.theme.sizes[p.iconSize]};
  text-transform: ${(p) => p.titleCase};
`;

export const IconSectionPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  iconName: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  iconMargin: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  titleSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  titleWeight: PropTypes.string,
  titleCase: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'initial']),
};

export const IconSectionDefaultProps = {
  children: null,
  iconColor: 'base',
  iconMargin: '3',
  titleColor: 'base',
  titleFont: 'title',
  titleSize: 1,
  titleCase: 'uppercase',
  titleWeight: 'bold',
};

const IconSection = ({
  children, iconName, iconColor, title, titleColor, size: iconSize, titleFont, titleLineHeight, titleSize, titleCase, titleWeight, iconMargin, ...props
}) => (
  <IconSectionContainer {...props}>
    <Icon size={iconSize} mr={iconMargin} color={iconColor} name={iconName} />
    <Box width="100%" pl={2}>
      {(typeof title === 'string') ? (
        <Title fontWeight={titleWeight} titleCase={titleCase} iconSize={iconSize} fontFamily={titleFont} fontSize={titleSize} color={titleColor} m="0">{title}</Title>)
        : title}
      {children}
    </Box>
  </IconSectionContainer>
);

IconSection.propTypes = {
  ...size.propTypes,
  ...IconSectionPropTypes,
};
IconSection.defaultProps = {
  size: 6,
  ...IconSectionDefaultProps,
};

export default styled(IconSection)``;
