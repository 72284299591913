import React from 'react';
import SectionLoading from '../SectionLoading';

const PageLoading = ({ children, ...rest }) => (
  <SectionLoading position="fixed" top="0" bottom="0" left="0" right="0" {...rest}>
    {children}
  </SectionLoading>
);

PageLoading.propTypes = {
  ...PageLoading.propTypes,
};

PageLoading.defaultProps = {
  ...PageLoading.defaultProps,
};

export default PageLoading;
