import styled from 'styled-components';
import {
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';

const Box = styled.div`
  box-sizing: border-box;
  ${space}
  ${width}
  ${color}
  ${textAlign}
  ${border}
  ${boxShadow}
`;

Box.displayName = 'Box';

Box.defaultProps = {
  width: '100%',
  borderRadius: 3,
  backgroundColor: 'base05',
  p: [5, 6, 7, 8],
};

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
};

export default Box;
