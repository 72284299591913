import { themeOverrides } from './themeOverrides';

const eCAFThemeOverrides = {
  name: 'eCAF',
  fonts: { body: '\'mr-eaves-xl-modern\', sans-serif', title: '\'mr-eaves-xl-modern\', sans-serif' },
  fontWeights: { light: 300, medium: 400, bold: 700 },
  lineHeights: { standard: 1.5, display: 1.2, compact: 1 },
  fontSizes: ['16px', '18px', '21px', '28px', '36px', '48px', '64px'],
  space: [
    '0', //      0
    '2px', //    1
    '4px', //    2
    '8px', //    3
    '12px', //   4
    '16px', //   5
    '24px', //   6
    '32px', //   7
    '48px', //   8
    '64px', //   9
    '72px', //   10
    '160px', //  11
    '260px', //  12
  ],
  threshold: {
    sectionMaxWidth: '1200px',
    contentMaxWidth: '760px',
  },
  colors: {
    ...themeOverrides,
    primaryDark: '#17a8e6',
    primary: '#19BBFF',
    primary80: '#47c9ff',
    primary60: '#75D6FF',
    primary40: '#a3e4ff',
    primary20: '#D1F1FF',
    primary10: '#e8f8ff',
    primary05: '#f2f9fd',
    secondaryDark: '#12b88e',
    secondary: '#14cc9e',
    secondary80: '#43d6b1',
    secondary60: '#72e0c5',
    secondary40: '#a1ebd8',
    secondary20: '#b9f0e2',
    secondary10: '#d0f5ec',
    secondary05: '#e8faf5',
    tertiaryDark: '#0ce6b0',
    tertiary: '#0dffc3',
    tertiary80: '#3dffcf',
    tertiary60: '#6effdb',
    tertiary40: '#9effe7',
    tertiary20: '#b6ffed',
    tertiary10: '#cffff3',
    tertiary05: '#e7fff9',
    success: '#5A9E28',
    successLight: '#C6E6AE',
    successDark: '#41850F',
    warn: '#D48004',
    warnLight: '#A7EB75',
    warnDark: '#BB6700',
    error: '#A81C39',
    errorLight: '#F4DEE3',
    errorDark: '#8F0320',
  },
};

export default eCAFThemeOverrides;
