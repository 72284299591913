import styled from 'styled-components';
import {
  color,
  textAlign,
  fontWeight,
} from 'styled-system';
import TR from './TR';
import TH from './TH';

const THead = styled.thead`
  ${TR} ${TH} {
    text-align: left;
    font-weight: 'bold';
    ${color};
    ${textAlign};
    ${fontWeight};
  }
`;

THead.displayName = 'THead';

THead.defaultProps = {
  textAlign: 'left',
  color: 'base80',
  fontWeight: 'medium',
  bg: 'transparent',
};

THead.propTypes = {
  ...textAlign.propTypes,
  ...color.propTypes,
  ...fontWeight.propTypes,
};

export default THead;
