import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  layout, letterSpacing, border, space,
} from 'styled-system';
import Icon from '../Icon';
import Flex from '../Flex';
import Box from '../Box';
import theme from '../../theme';

const Badge = styled(Flex)`
  align-items:center;
  align-self:center;
  flex-shrink:0;
  overflow:hidden;
  text-align:center;
  font-size:10px;
  line-height:12px;
  text-transform:${(p) => p.textTransform};
  font-weight:${(p) => p.fontWeight || 'normal'};
  padding:${(p) => `${p.theme.space[2]} ${p.theme.space[2]}`};
  border-radius:${(p) => p.theme.radii[4]};
  color:${(p) => p.theme.colors.white};
  background-color:${(p) => p.theme.colors[p.color]};
  ${layout};
  ${border};
  ${space};
  ${letterSpacing};
`;

const WrappedBadge = ({
  children, icon, iconColor, ...props
}) => (
  <Badge {...props}>
    <Icon name={icon} color={iconColor} size="12px" />
    <Box mx={3}>{children}</Box>
  </Badge>
);

WrappedBadge.defaultProps = {
  icon: 'NavRightCircle',
  iconColor: 'white',
  color: 'primary',
  letterSpacing: 'button',
  textTransform: 'uppercase',
};

WrappedBadge.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'none']),
  letterSpacing: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
};

Badge.propTypes = {
  ...WrappedBadge.propTypes,
};
export default WrappedBadge;
