import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconSection from './IconSection';
import UL from './Lists/UL';
import LI from './Lists/LI';

const StyledLI = styled(LI)`
  margin-right: ${(p) => p.theme.space[6]};
  font-size: ${(p) => p.theme.fontSizes[0]};
  white-space: pre-wrap;
`;

const ErrorSummary = ({ errors, title, mb }) => {
  if (!errors || errors.length === 0) return null;
  const deDupedErrors = [...new Set(errors)];
  function replaceNBSP(data) {
    return data.replace('&nbsp;', ' ');
  }
  return (
    <IconSection
      width="100%"
      iconColor="error"
      titleColor="error"
      p="4"
      mb={mb}
      bg="errorLight"
      iconName="WarningCircle"
      title={title}
      borderRadius="4px"
    >
      <UL nobullets>
        { deDupedErrors.map((error) => <StyledLI key={error} color="error">{replaceNBSP(error)}</StyledLI>)}
      </UL>
    </IconSection>
  );
};

ErrorSummary.defaultProps = {
  errors: [],
  mb: '4',
};

ErrorSummary.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
};

export default ErrorSummary;
