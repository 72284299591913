import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  layout, space, typography, letterSpacing, border,
} from 'styled-system';
import Flex from '../Flex';
import Icon from '../Icon';
import Box from '../Box';

const ButtonText = styled.span`
  text-transform: uppercase;
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    display: none;
  }
`;

const getVariant = (props) => {
  const secondary = `
    background-color: ${props.theme.colors.secondary};
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: ${props.theme.colors.white};
    &:hover {
      background-color: ${(props.disabled ? props.theme.colors.secondary : props.theme.colors.secondaryDark)};
    }
  `;

  const link = `
    background-color: transparent;
    border: 1px solid transparent;
    color: ${props.theme.colors.secondary};
    &:hover {
      color: ${props.theme.colors.secondaryDark};
    }
  `;

  return props.callToAction ? secondary : link;
};

const getIconVariant = (props) => {
  const secondary = `
    color: white;
  `;

  const link = `
    color: ${props.theme.colors.base};
  `;

  return props.callToAction ? secondary : link;
};

const ButtonIcon = styled(Icon)`
  width: 12px;
  ${(p) => (p.left
    ? `margin-right: ${p.theme.space[4]};`
    : `margin-left: ${p.theme.space[4]};`)}

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    ${(p) => (p.left
    ? `margin-right: ${p.theme.space[0]};`
    : `margin-left: ${p.theme.space[0]};`)}
  }
  ${getIconVariant}
`;

const NoButton = styled.div`
  display: inline-block;
  min-height: 70px;
  min-width: 160px;
  border-left: 1px solid transparent;
  
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    min-width: ${(p) => p.theme.space[8]};
  }
`;

const ResponsiveButton = styled.button`
  user-select: none;
  text-decoration: none;
  text-transform: ${(p) => p.textTransform};
  display: inline-block;
  cursor: pointer;
  border-width: 0;
  border-radius: 0;
  min-height: 70px;
  border-style: solid;
  border-radius: 0;
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: ${(props) => (props.noWrap ? 'nowrap' : 'normal')};

  &:disabled{
    cursor:not-allowed;
  }

  ${getVariant};
  ${space};
  ${layout};
  ${typography};
  ${letterSpacing};
  ${border};
  
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    border-radius: 25px;
    min-height: 0;
    min-width: 0;
    padding: 11px 16px;
  }
`;
ResponsiveButton.defaultProps = {
  margin: '0',
  minWidth: '160px',
  padding: '11px 30px',
};

const BackButton = ({ onClick }) => (
  <NavButtonWrapper left>
    <ResponsiveButton
      onClick={onClick}
    >
      <ButtonIcon name="ArrowLeft" left />
      <ButtonText>Back</ButtonText>
    </ResponsiveButton>
  </NavButtonWrapper>
);

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const NavButtonWrapper = styled(Box)`
    ${(p) => (p.left
    ? `border-right: 1px solid ${p.theme.colors.base20};`
    : `border-left: 1px solid ${p.theme.colors.base20};`)}
  
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    border-width: 0;
    padding-left: 0;
    border-color: transparent;
  }
`;

const CenterAction = styled(Box)`
  width: 260px;
  text-align: center;
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    width: 220px;
  }
`;

CenterAction.defaultProps = {};

const Wrapper = styled(Flex)`
    position: fixed;
    bottom: 0;
    justify-content: space-between;
    left: 0;
    background-color: white;
    align-items: center;
    z-index: 1;
    min-height: 70px;
    width: 100%;
`;

Wrapper.defaultProps = {
  bg: 'primary',
  boxShadow: 'xl',
};

const ActionBar = ({
  showNext, showSkip, nextText, onNextClick, onBackClick, children, ...rest
}) => {
  const history = useHistory();

  let rightButton = null;
  if (showNext || showSkip) {
    rightButton = (
      <NavButtonWrapper right>
        <ResponsiveButton
          callToAction={showNext}
          onClick={onNextClick}
        >
          <ButtonText>{showNext ? nextText : 'Skip'}</ButtonText>
          <ButtonIcon callToAction={showNext} name="ArrowRight" />
        </ResponsiveButton>
      </NavButtonWrapper>
    );
  } else {
    rightButton = <NoButton />;
  }
  return (
    <>
      <Wrapper {...rest}>
        <BackButton onClick={() => (onBackClick ? onBackClick() : history.goBack())} />
        <CenterAction>
          {children}
        </CenterAction>
        {rightButton}
      </Wrapper>
    </>
  );
};

ActionBar.defaultProps = {
  pl: [null, null, '70px'],
  children: null,
  onNextClick: () => { },
  onBackClick: undefined,
  showNext: false,
  showSkip: true,
  nextText: 'Next',
};

ActionBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nextText: PropTypes.string,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  showNext: PropTypes.bool,
  showSkip: PropTypes.bool,
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
};

export default ActionBar;
