import styled from 'styled-components';
import {
  maxWidth,
  size,
  space,
  width,
  height,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';

const Box = styled.div`
  box-sizing: border-box;
  ${space}
  ${size}
  ${width}
  ${height}
  ${color}
  ${textAlign}
  ${border}
  ${boxShadow}
  ${maxWidth}
`;

Box.displayName = 'Box';

Box.defaultProps = {
};

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
  ...size.propTypes,
  ...maxWidth.propTypes,
};

export default Box;
