/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Box from '../Box';
import Flex from '../Flex';
import Input from '../Input';
import useOnClickOutside from '../../utils/useOnClickOutside';

const getControlVariant = (props) => {
  const def = `
    padding: ${props.theme.space[5]};
    height: 50px;
    border-radius: ${props.theme.radii[2]};
    border-width: 0px;
    border-style: solid;
    border-color: ${props.isFocused ? props.theme.colors.base40 : props.theme.colors.base20};
    box-shadow : ${props.isFocused ? `0 0 0 2px ${props.theme.colors.base40}` : `0 0 0 1px ${props.theme.colors.base20}`};
    `;
  return props.type === 'default' ? def : null;
};

const Control = styled(Flex)`
  cursor: pointer;
  position: relative;
  ${getControlVariant}
`;

const Menu = styled(Flex)`
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%);
  display: flex;
  height: auto;
  max-height: 300px;
  overflow: auto;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const Option = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.base10};
  }
`;

const IconWrapper = styled(Box)`
  position: absolute;
  right: 10px;
`;

const StyledInput = styled(Input)`
  border-radius: unset;
`;

const Wrapper = styled(Box)`
  width: ${(p) => p.width};
`;

const Select = ({
  controlStyles, defaultValue, rightIcon, menuStyles, onChange, options, enableSearch, optionStyles, type, width,
}) => {
  const defaultOption = defaultValue ? options.find((option) => (option.searchBy ? option.searchBy === defaultValue : option.value === defaultValue)) : '';
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [showMenu, setShowMenu] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef();

  useOnClickOutside(selectRef, () => {
    setShowMenu(false);
    setIsFocused(false);
  });

  useEffect(() => {
    onChange(selectedOption.value);
  }, [onChange, selectedOption]);

  return (
    <Wrapper ref={selectRef} width={width} onClick={() => setIsFocused(true)}>
      <Control {...controlStyles} alignItems="center" flexDirection="row" isFocused={isFocused} onClick={() => setShowMenu(!showMenu)} type={type}>
        {selectedOption.controlLabel || selectedOption.label}
        {rightIcon ? (
          <IconWrapper>
            {rightIcon}
          </IconWrapper>
        ) : null}
      </Control>
      {showMenu ? (
        <Menu {...menuStyles} flexDirection="column" p={3}>
          {enableSearch ? <StyledInput onChange={(evt) => setSearchValue(evt.currentTarget.value)} placeholder="Search..." value={searchValue} /> : null}
          {enableSearch ? options.map((option, index) => {
            const searchMatch = option.searchBy ? option.searchBy.toLowerCase().indexOf(searchValue.toLowerCase()) === 0 : option.value.toLowerCase().indexOf(searchValue.toLowerCase()) === 0;
            const filteredOption = (
              <Option
                {...optionStyles}
                key={`${option.value}${index}`}
                onClick={() => {
                  setShowMenu(false);
                  setSearchValue('');
                  setSelectedOption(option);
                }}
              >
                {option.label}
              </Option>
            );
            if (searchValue === '') {
              return filteredOption;
            } if (searchValue && searchMatch) {
              return filteredOption;
            }
            return null;
          }) : options.map((option) => (
            <Option
              {...optionStyles}
              key={option.value}
              onClick={() => {
                setShowMenu(false);
                setSearchValue('');
                setSelectedOption(option);
              }}
            >
              {option.label}
            </Option>
          ))}
        </Menu>
      ) : null}
    </Wrapper>
  );
};

Select.defaultProps = {
  controlStyles: {
  },
  defaultValue: null,
  enableSearch: false,
  menuStyles: {
  },
  onChange: () => {},
  options: [],
  optionStyles: {
    p: 4,
    bg: 'white',
    width: '100%',
  },
  rightIcon: null,
  type: null,
  width: '100%',
};

Select.propTypes = {
  controlStyles: PropTypes.shape({}),
  defaultValue: PropTypes.string,
  enableSearch: PropTypes.bool,
  onChange: PropTypes.func,
  menuStyles: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    }),
  ),
  optionStyles: PropTypes.shape({}),
  rightIcon: PropTypes.node,
  type: PropTypes.string,
  width: PropTypes.string,
};

export default Select;
