import styled from 'styled-components';
import {
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';

const DD = styled.dd`
  box-sizing: border-box;
  flex-basis: 70%;
  flex-grow: 1;
  ${space}
  ${width}
  ${color}
  ${textAlign}
  ${border}
  ${boxShadow}
  white-space: wrap;
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    flex-basis: 100%;
  }
`;

DD.displayName = 'DD';

DD.defaultProps = {
  m: 0,
  py: 3,
};

DD.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
};

export default DD;
