import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { layout } from 'styled-system';
import Flex from './Flex';

const getSize = (p) => {
  switch (p.scale) {
    case 'sm':
      return '10px';
    default:
      return themeGet(`fontSizes.${p.fontSize}`, p.fontSize)(p);
  }
};

const getPadding = (p) => {
  switch (p.scale) {
    case 'sm':
      return `${p.theme.space[2]} ${p.theme.space[4]}`;
    default:
      return `${p.theme.space[3]} ${p.theme.space[5]}`;
  }
};

const getBorderRadius = (p) => {
  switch (p.scale) {
    case 'sm':
      return p.theme.radii[4];
    default:
      return p.theme.radii[5];
  }
};

const Tag = styled(Flex)`
  text-transform: uppercase;
  font-size: ${getSize};
  padding: ${getPadding};
  border-radius: ${getBorderRadius};
  ${layout};
`;

Tag.propTypes = {
  type: PropTypes.oneOf(['warn', 'success', 'error']),
  scale: PropTypes.oneOf(['sm', 'md']),
  borderRadius: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  leftIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  iconColor: PropTypes.string,
};

const WrappedTag = ({
  children, leftIcon, rightIcon, ...props
}) => (
  <Tag
    leftIcon={leftIcon}
    rightIcon={rightIcon}
    {...props}
  >
    {leftIcon}
    {children}
    {rightIcon}
  </Tag>
);

WrappedTag.defaultProps = {
  color: 'white',
  bg: 'base',
  fontSize: 0,
  iconColor: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  type: 'success',
  scale: 'md',
};

WrappedTag.propTypes = {
  ...Tag.PropTypes,
  ...layout.PropTypes,
};

export default WrappedTag;
