import styled from 'styled-components';
import PropTypes from 'prop-types';
import { space, layout } from 'styled-system';
import Box from '../Box';
import { H1, H4 } from '../Heading';
import ActionContainer from '../ActionContainer';
import Header from './components/Header';
import Progress from './components/Progress';

const StyledSection = styled(Box)`
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.base20};
  user-select: ${(p) => (p.userSelect ? 'initial' : 'none')};
  border: ${(p) => (p.borderCollapse ? 'none' : `1px solid ${p.theme.colors.base20}`)};
  margin-top: ${(p) => (p.progressOffset ? `${p.theme.space[4]}` : 0)};
  padding:  ${(p) => `${p.theme.space[7]} ${p.theme.space[10]}`};

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    padding: ${(p) => p.theme.space[7]};
  }

  @media (min-width: ${(p) => p.theme.breakpoints[1]}) {
    padding:  ${(p) => p.theme.space[10]};
  }
`;

StyledSection.defaultProps = {
  borderCollapse: false,
  userSelect: true,
  maxWidth: null,
  progressOffset: false,
};

StyledSection.propTypes = {
  ...space.propTypes,
  borderCollapse: PropTypes.bool,
  userSelect: PropTypes.bool,
  maxWidth: PropTypes.string,
  progressOffset: PropTypes.bool,
};

const StyledContent = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.threshold.contentMaxWidth};
`;

const StyledTitle = styled(H1)``;

StyledTitle.defaultProps = {
  m: 0,
  mb: [6, 7],
};

StyledTitle.propTypes = {
  ...space.propTypes,
};

const StyledSubtitle = styled(H4)``;

StyledSubtitle.defaultProps = {
  m: 0,
  mb: 4,
};

StyledSubtitle.propTypes = {
  ...space.propTypes,
};

const Panel = styled(Box)`
  width: 100%;
  max-width: ${(p) => p.maxWidth || p.theme.threshold.sectionMaxWidth};
  background-color: ${(p) => p.theme.colors.white};
  margin-bottom: ${(p) => p.theme.space[11]};
  border-radius: 0;
  box-shadow: ${(p) => p.theme.shadows.none};

  @media (min-width: ${(p) => p.theme.breakpoints[1]}) {
    border-radius: ${(p) => p.theme.radii[3]};
    box-shadow: ${(p) => p.theme.shadows.lg};
  }
`;

Panel.Section = StyledSection;
Panel.Content = StyledContent;
Panel.Title = StyledTitle;
Panel.Subtitle = StyledSubtitle;
Panel.ActionContainer = ActionContainer;
Panel.Header = Header;
Panel.Progress = Progress;

Panel.defaultProps = {
  p: [6, 7],
  maxWidth: null,
};

Panel.propTypes = {
  ...space.propTypes,
  ...layout.PropTypes,
};

export default Panel;
