/* eslint-disable react/default-props-match-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ActionContainer from './components/ActionContainer';
import Flex from '../Flex';

const Wrapper = styled(Flex)`
  background-color: white;
  border-left: 6px solid ${(p) => p.theme.colors[p.borderColor]};
  min-height: ${(p) => p.minHeight};
  min-width: ${(p) => p.minWidth};
  position: relative;
  width: 100%;
  margin: ${(p) => `${p.theme.space[2]} 0`};
  flex-direction: column;
`;

const ListingsCard = ({
  children, borderColor, minHeight, ...props
}) => (
  <Wrapper {...props} minHeight={minHeight} borderColor={borderColor}>
    {children}
  </Wrapper>
);

ListingsCard.ActionContainer = ActionContainer;

export default ListingsCard;

ListingsCard.defaultProps = {
  borderColor: 'base80',
  children: null,
  p: ['27px 15px', '27px 25px'],
  minHeight: 'auto',
  minWidth: 'auto',
};

ListingsCard.propTypes = {
  borderColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  ...Flex.PropTypes,
};
