import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Flex from '../Flex';
import { H3, H6 } from '../Heading';
import ItemList from './components/ItemList';
import Header from './components/Header';

const List = styled(Flex)`
  flex-flow: column nowrap;
  width: 100%;
`;
export default function StepsItem({ children }) {
  return (
    <List>
      {children}
    </List>
  );
}

StepsItem.defaultProps = {
  children: null,
};

StepsItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

StepsItem.Header = Header;
StepsItem.List = ItemList;
StepsItem.Title = styled(H3)``;
StepsItem.Link = styled(H6)`
  color: ${(p) => p.theme.colors.secondary};
  cursor: pointer;
`;
