import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { shadow } from 'styled-system';
import Box from '../Box';

const ArrowBox = styled(Box)`
  visibility:  ${(p) => (p.show ? 'visible' : 'hidden')};
  margin-top: ${(p) => p.contentMargin};
  position: relative;
  min-width: ${(p) => (p.minWidth)};

  &::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;

    bottom: 100%;
    left: ${(p) => p.arrowPosition};
    margin-left: -5px;

    border-color: transparent transparent ${(p) => themeGet(`colors.${p.bg}`, p.bg)(p)} transparent;
    ${shadow}
  }
`;

ArrowBox.defaultProps = {
  arrowPosition: '50%',
  contentMargin: '6px',
  bg: 'white',
  show: false,
  p: 3,
  borderRadius: 2,
  minWidth: 'auto',
  ...shadow.defaultProps,
};

ArrowBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  arrowPosition: PropTypes.string,
  contentMargin: PropTypes.string,
  minWidth: PropTypes.string,
  ...shadow.propTypes,
};

export default ArrowBox;
