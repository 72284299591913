import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { padding } from 'styled-system';
import Box from './Box';
import Flex from './Flex';
import { H3 } from './Heading';
import Icon from './Icon';

const IconWrapper = styled(Box)`
  height: 100%;
  min-height: 54px;
  min-width: 54px;
  max-height: 64px;
  max-width: 64px;
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  height: 100%;
  width: 100%;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  flex-direction: row;
  background-color: ${(p) => themeGet(`colors.${p.bg}`, p.bg)(p)};
  padding: ${(p) => `${p.theme.space[8]} ${p.theme.space[10]}`};
  ${padding};

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    padding: ${(p) => `${p.theme.space[8]} ${p.theme.space[7]}`};
    ${padding};
  }
`;

const TaskNotification = ({
  title, titleSize, type, ...props
}) => {
  const iconName = type === 'success' ? 'CheckCircle' : 'WarningCircle';

  return (
    <Wrapper bg={type} {...props}>
      <IconWrapper mr="18px">
        <StyledIcon color="white" name={iconName} mr="5" />
      </IconWrapper>
      <Box width="100%" pl={2}>
        <H3 color="white" fontSize={titleSize}>{title}</H3>
      </Box>
    </Wrapper>
  );
};

TaskNotification.defaultProps = {
  titleSize: null,
};

TaskNotification.propTypes = {
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success', 'warn']).isRequired,
};

export default TaskNotification;
