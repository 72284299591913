import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@galilee/lilicons/lib/Icon';
import { color, size } from 'styled-system';

const spinIcon = ({ spin, stepping }) => {
  if (spin) return 'animation: spin 2s linear infinite;';
  if (stepping) return 'animation: 1s clock infinite steps(1);';
  return null;
};

const StyledIcon = styled(Icon)`

  @keyframes clock {
    0% {
      transform: rotate(0deg);
    }
    12.5%{
      transform: rotate(45deg);
    }
    25%{
      transform: rotate(90deg);
    }
    37.5%{
      transform: rotate(135deg);
    }
    50%{
      transform: rotate(180deg);
    }
    62.5%{
      transform: rotate(225deg);
    }
    75%{
      transform: rotate(270deg);
    }
    87.5%{
      transform: rotate(315deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
      0% { transform: rotate(359deg); }
      100% {  transform: rotate(0deg); }
  }
  ${color}
  ${size}
  ${spinIcon}
`;

StyledIcon.defaultProps = {
  color: 'base',
  spin: false,
  stepping: false,
};

StyledIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  title: PropTypes.string,
  titleId: PropTypes.string,
  desc: PropTypes.string,
  descId: PropTypes.string,
  spin: PropTypes.bool,
  stepping: PropTypes.bool,
};

export default StyledIcon;
