import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ZoomIn from '../../images/ZoomIn.png';
import ZoomOut from '../../images/ZoomOut.png';
import Flex from '../Flex';

const Image = styled.img`
  pointer-events: none;
`;

const StyledButton = styled.button`
  align-items: center;
  background-color: ${(p) => p.theme.colors.secondary};
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  outline: 0;
  width: 48px;
`;

const ZoomButtonWrapper = styled(Flex)`
  bottom: ${(p) => (p.bottom)};
  left: 0;
  position: fixed;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${(p) => (p.zoomed ? 'z-index: 991;' : null)}
`;

const ZoomButton = ({ onClick, zoomed }) => (
  <StyledButton onClick={onClick}>{zoomed ? <Image alt="zoom in" src={ZoomIn} /> : <Image alt="zoom out" src={ZoomOut} />}</StyledButton>
);

ZoomButton.Wrapper = ZoomButtonWrapper;

export default ZoomButton;

ZoomButton.defaultProps = {
};

ZoomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  zoomed: PropTypes.bool.isRequired,
};
