import styled from 'styled-components';
import { color } from 'styled-system';
import React from 'react';
import PropTypes from 'prop-types';
import { mediaQueries } from '../theme';
import HR from './HR';
import Flex from './Flex';

const { greaterThan } = mediaQueries;

const StyledCard = styled(Flex)`
  cursor: pointer;
  display: grid;
  gap: ${(p) => p.theme.space[5]};
  border-left: 6px solid ${(p) => p.theme.colors[p.borderColor]};
  width: 100%;
  margin: ${(p) => `${p.theme.space[2]} 0`};
  padding: ${(p) => `${p.theme.space[7]} ${p.theme.space[4]} ${p.theme.space[7]} ${p.theme.space[6]}`};

  grid-template-areas:
      "Title Firm"
      "Status Action";

  &:hover{
    background-color: ${(p) => p.theme.colors.base05};
  }

  ${greaterThan[1]`
    grid-template-columns: auto 2fr 1fr auto;
    grid-template-areas: "Firm Title Status Action";
  `}
`;

const Listing = styled.div`
  margin: ${(p) => `0 ${p.theme.space[5]}`};
  padding: ${(p) => p.theme.space[4]};
  border-radius: ${(p) => p.theme.radii[2]};  
  ${color}

  ${greaterThan[1]`
    margin: 0;
  `}
`;

Listing.Card = ({ children, ...rest }) => (
  <>
    <StyledCard {...rest}>{children}</StyledCard>
    <HR m="0" />
  </>
);

Listing.Card.defaultProps = {
  children: null,
  borderColor: 'base80',
};
Listing.Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  borderColor: PropTypes.string,
};


Listing.Card.Title = styled.div`
  grid-area: Title;
  align-self: center;
  display: flex;
  flex-flow: column nowrap;
`;

Listing.Card.Status = styled.div`
  grid-area: Status;
  align-self: center;
  display: flex;
  flex-flow: row wrap;
  gap: ${(p) => p.theme.space[2]};
  align-items: center;
  text-align: right;

  ${greaterThan[0]`
    flex-flow: row nowrap;
  `}
`;

Listing.Card.Action = styled.div`
  grid-area: Action;
  cursor: pointer;
  align-self: center;
  justify-self: flex-end;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

Listing.Card.Firm = styled.div`
  grid-area: Firm;
  align-self: center;
`;

export default Listing;
