import PropTypes from 'prop-types';
import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Flex from '../../Flex';
import HR from '../../HR';
import AccordionRow from '../../AccordionRow';

const StyledList = styled(Flex)`
  flex-flow: column nowrap;
  margin-bottom: ${(p) => p.theme.space[10]};
`;

export default function ItemList({ items, openState, onStateChange }) {
  const accordionState = items.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.open ?? false }), {});
  const [openStates, setOpenStates] = useState(accordionState);

  useEffect(() => {
    if (!openState) return;
    setOpenStates(openState);
  }, [openState]);

  return (
    <StyledList>
      {items.map((step) => (
        <Fragment key={step.id}>
          <HR width="100%" my="0" />
          <AccordionRow
            key={step.id}
            type="step"
            contentBg="base05"
            heading={step.heading}
            icon={step.icon}
            iconColor={step.iconColor}
            isOpen={openStates[step.id]}
            onClick={() => {
              const newState = { ...openStates, [step.id]: !openStates[step.id] };
              setOpenStates(newState);
              onStateChange(newState);
            }}
          >
            {step.content}
          </AccordionRow>
        </Fragment>
      ))}
    </StyledList>
  );
}
ItemList.defaultProps = {
  items: null,
  openState: null,
  onStateChange: () => {},
};
ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    heading: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    open: PropTypes.bool,
    content: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  })),
  openState: PropTypes.shape({
    [PropTypes.oneOf([PropTypes.string, PropTypes.number])]: PropTypes.bool,
  }),
  onStateChange: PropTypes.func,
};
