import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  space, fontSize, fontWeight, color,
} from 'styled-system';

const nowrap = (props) => (props.nowrap
  ? {
    whiteSpace: 'nowrap',
  }
  : null);

const accessiblyHide = (props) => (props.hidden
  ? {
    position: 'absolute',
    width: '1px',
    height: '1px',
    clip: 'rect(1px, 1px, 1px, 1px)',
  }
  : null);

const Label = styled.label`
  text-transform: ${(p) => p.textTransform};  
  font-size: 10px;
  display: block;
  width: 100%;
  margin-bottom: ${(p) => p.theme.space[4]};
  user-select: none;
  ${space}
  ${fontSize}
  ${color}
  ${fontWeight};
  ${nowrap}
  ${accessiblyHide}
`;

Label.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...fontWeight.propTypes,
  textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'none']),
};

Label.defaultProps = {
  fontSize: '0',
  fontWeight: 'medium',
  color: 'base',
  textTransform: 'uppercase',
};

export default Label;
