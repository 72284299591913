import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from '../../Box';

const Wrapper = styled(Box)`
  border-top-left-radius: inherit;  
  border-top-right-radius: inherit;
  position: relative;
  padding: ${(p) => p.theme.space[10]};
  margin: 0;

  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    padding: ${(p) => p.theme.space[7]};
  }
`;

const Header = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    {children}
  </Wrapper>
);

export default Header;

Wrapper.defaultProps = {
  bg: 'base',
  height: 'auto',
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  ...Box.propTypes,
};
