import React from 'react';
import styled from 'styled-components';
import Flex from '../../Flex';

const Cell = styled(Flex)`
  justify-content: center;
`;

Cell.defaultProps = {
  p: 8,
};

const Loader = () => <Cell>Loading ...</Cell>;

Loader.propTypes = {
};

export default Loader;
