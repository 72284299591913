import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H4 } from '../Heading';
import ActionContainer from '../ActionContainer';
import Container, { NoScrollBodyStyle } from './components/Container';
import Title from './components/Title';
import Close from './components/Close';
import ModalBox from './components/ModalBox';
import IllustrationBody from './components/IllustrationBody';
import Body from './components/Body';

const Modal = ({ children, overlayColor, ...rest }) => (
  <Container backgroundColor={overlayColor}>
    <NoScrollBodyStyle />
    <ModalBox {...rest}>
      {children}
    </ModalBox>
  </Container>
);

Modal.defaultProps = {
  ...ModalBox.defaultProps,
  overlayColor: 'baseOpacity60',
};

Modal.propTypes = {
  ...ModalBox.propTypes,
  overlayColor: PropTypes.string,
};

const Subtitle = styled(H4)``;

Subtitle.defaultProps = {
  mt: '2',
  mb: '4',
};

Modal.Close = Close;
Modal.Title = Title;
Modal.Subtitle = Subtitle;
Modal.Body = Body;
Modal.IllustrationBody = IllustrationBody;
Modal.ActionContainer = ActionContainer;

export default Modal;
