import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import CheckBoxWrapper from './components/CheckBoxWrapper';

const Checkbox = (props) => {
  const {
    disabled, size, label, checked, onChange, ...rest
  } = props;
  return (
    <CheckBoxWrapper disabled={disabled} {...rest}>
      <StyledInput type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
      <Icon name="CheckedBox" size={size} data-name="checked" mr="3" />
      <Icon name="EmptyBox" size={size} data-name="empty" mr="3" />
      {label}
    </CheckBoxWrapper>
  );
};

const StyledInput = styled.input`
  appearance: none;
  opacity: 0;
  position: absolute;
  z-index: 0;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'initial')};
`;

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  size: PropTypes.number,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Checkbox.defaultProps = {
  size: 20,
  disabled: false,
  checked: false,
};

export default Checkbox;
