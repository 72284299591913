import styled from 'styled-components';
import IconSection from '../../IconSection';

const Item = styled(IconSection)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

export default Item;

Item.defaultProps = {
  borderTop: '1px solid',
  borderColor: 'base10',
  iconMargin: '4',
  iconSize: '18px',
  p: '20px',
  pl: 6,
  titleCase: 'initial',
  titleSize: '18px',
};

Item.propTypes = {
  ...IconSection.propTypes,
};
