import styled from 'styled-components';
import PropTypes from 'prop-types';
import { layout, space, border } from 'styled-system';
import Flex from '../Flex';

const getFullWidth = ({
  fullWidth, fullWidthBreakpointIndex, wrapperGroup, theme,
}) => {
  const fullWidthStyle = { width: '100%' };
  if (fullWidth) return fullWidthStyle;
  if (fullWidthBreakpointIndex !== undefined && fullWidthBreakpointIndex !== null) {
    if (wrapperGroup) {
      return `
        @media (max-width: ${theme.breakpoints[fullWidthBreakpointIndex]}) {
          min-width: 100%;
          flex-direction: column;
          flex-wrap: wrap;
        }
      `;
    }
    return `
      @media (max-width: ${theme.breakpoints[fullWidthBreakpointIndex]}) {
        min-width: 100%;
      }
    `;
  }
  return null;
};

const ResponsiveGroup = styled(Flex)`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;
  align-self: flex-start;
  ${layout};
  ${space};
  ${getFullWidth};
`;

ResponsiveGroup.defaultProps = {
  backgroundColor: 'transparent',
  wrapperGroup: true,
  borderRadius: 5,
  fullWidthBreakpointIndex: null,
};

ResponsiveGroup.propTypes = {
  wrapperGroup: PropTypes.bool,
  fullWidthBreakpointIndex: PropTypes.number,
  ...layout.propTypes,
  ...space.propTypes,
  ...border.propTypes,
};

export default ResponsiveGroup;
