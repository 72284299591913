import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from '../../Flex';
import Image from '../../Image';
import Body from './Body';

const IllustrationWrapper = styled(Flex)`
  border-top-left-radius: ${p => p.theme.radii[3]};
  border-bottom-left-radius: ${p => p.theme.radii[3]};
  align-items: center;
  justify-content: center;
  min-width: 310px;
  min-height: 625px;
  @media (max-width: ${p => p.theme.breakpoints[1]}) {
    display: none;
  }
`;

IllustrationWrapper.defaultProps = {
  backgroundColor: 'base',
};

const IllustrationImage = styled(Image)`
  @media (max-width: ${p => p.theme.breakpoints[1]}) {
    display: none;
  }
`;

const IllustrationBody = ({ image, children }) => (
  <Flex p="0" flexWrap="nowrap">
    <IllustrationWrapper>
      <IllustrationImage src={image} />
    </IllustrationWrapper>
    <Body>
      {children}
    </Body>
  </Flex>
);

IllustrationBody.propTypes = {
  image: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default IllustrationBody;
