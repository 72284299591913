/* eslint-disable react/forbid-prop-types */

import React, {
  useReducer, useMemo, createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import * as validators from '../../validators';

function validateField(fieldValues, fieldValue = '', fieldConfig) {
  const specialProps = ['initialValue'];
  let errorMessage = null;
  Object.keys(fieldConfig).find((validatorName) => {
    if (!specialProps.includes(validatorName)) {
      let validatorConfig = fieldConfig[validatorName];
      if (validatorConfig.fieldRef) {
        validatorConfig.value = fieldValues[validatorConfig.fieldRef];
      }
      if (typeof validatorConfig === 'string') {
        validatorConfig = { message: validatorConfig };
      }
      errorMessage = validators[validatorName](validatorConfig)(fieldValue);
      return typeof errorMessage === 'string'; // First error message found, stop validating anything else.
    }
    return false;
  });
  return errorMessage;
}


function validateFields(fieldValues, fieldConfigs) {
  const errors = {};
  Object.keys(fieldConfigs).forEach((fieldName) => {
    const fieldConfig = fieldConfigs[fieldName];
    const fieldValue = fieldValues[fieldName];
    errors[fieldName] = validateField(fieldValues, fieldValue, fieldConfig);
  });

  return errors;
}

function getInitialState(config) {
  const initialValues = {};
  const initialBlurred = {};
  Object.keys(config.fields).forEach((fieldName) => {
    initialValues[fieldName] = config.fields[fieldName].initialValue || '';
    initialBlurred[fieldName] = false;
  });
  const initialErrors = validateFields(initialValues, config.fields);
  return {
    values: initialValues,
    errors: initialErrors,
    blurred: initialBlurred,
    submitted: false,
  };
}

function validationReducer(state, action) {
  switch (action.type) {
    case 'change':
      return {
        ...state,
        values: { ...state.values, ...action.payload },
      };
    case 'submit':
      return { ...state, submitted: true };
    case 'validate':
      return { ...state, errors: action.payload };
    case 'blur':
      return {
        ...state,
        blurred: { ...state.blurred, [action.payload]: true },
      };
    default:
      throw new Error('Unknown action type');
  }
}

function getErrors(state, config) {
  if (config.showErrors === 'always' || state.submitted) {
    return state.errors;
  }
  if (config.showErrors === 'blur') {
    return Object.entries(state.blurred)
      .filter(([, blurred]) => blurred)
      .reduce((acc, [name]) => ({ ...acc, [name]: state.errors[name] }), {});
  }
  return {};
}

const initValidation = (config) => {
  const [state, dispatch] = useReducer(
    validationReducer,
    getInitialState(config),
  );

  useDeepCompareEffect(() => {
    const errors = validateFields(state.values, config.fields);
    dispatch({ type: 'validate', payload: errors });
  }, [state.values, config.fields]);

  const errors = useMemo(() => getErrors(state, config), [state]);

  const isFormValid = () => Object.values(state.errors).every((error) => error === null);

  return {
    errors,
    submitted: state.submitted,
    isFormValid,
    getFormProps: (overrides = {}) => ({
      onSubmit: (e) => {
        e.preventDefault();
        dispatch({ type: 'submit' });
        if (overrides.onSubmit) {
          overrides.onSubmit({ ...state, isFormValid });
        }
      },
    }),
    getFieldProps: (fieldName, overrides = {}) => ({
      onChange: (e) => {
        const { value } = e.target;
        if (!config.fields[fieldName]) {
          return;
        }
        dispatch({
          type: 'change',
          payload: { [fieldName]: value },
        });
        if (overrides.onChange) {
          overrides.onChange(e);
        }
      },
      onBlur: (e) => {
        dispatch({ type: 'blur', payload: fieldName });
        if (overrides.onBlur) {
          overrides.onBlur(e);
        }
      },
      name: overrides.name || fieldName,
      value: state.values[fieldName] || '',
      blurred: state.blurred[fieldName] || false,
    }),
  };
};

const ValidationContext = createContext({});

export const useValidation = () => useContext(ValidationContext);

export const ValidationProvider = ({ config, children }) => {
  const context = initValidation(config);
  const memoizedContext = useMemo(() => context, [context]);
  return (
    <ValidationContext.Provider value={memoizedContext}>
      {children}
    </ValidationContext.Provider>
  );
};

ValidationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  config: PropTypes.object.isRequired,
};
