import styled from 'styled-components';
import {
  fontSize,
  space,
  width,
  color,
  textAlign,
  border,
  boxShadow,
} from 'styled-system';

const LI = styled.li`
${fontSize}
${space}
${width}
${color}
${textAlign}
${border}
${boxShadow}
`;

LI.displayName = 'LI';

LI.defaultProps = {
};

LI.propTypes = {
  ...fontSize.propTypes,
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes,
  ...border.propTypes,
};

export default LI;
