import styled from 'styled-components';
import {
  space, shadow, typography, color as styledColor, layout,
} from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';

const disable = ({ disabled = false }) => ({
  cursor: disabled ? 'not-allowed' : 'initial',
});

const borders = ({ color, theme, boxShadow }) => {
  const borderColor = color ? theme.colors[color] : theme.colors.base20;
  const focusColor = color ? theme.colors[color] : theme.colors.base40;
  return {
    'border-color': borderColor,
    'box-shadow': boxShadow || `0 0 0 1px ${borderColor}`,
    ':focus': {
      outline: 0,
      'border-color': focusColor,
      'box-shadow': `0 0 0 2px ${focusColor}`,
    },
  };
};

const Input = styled.input`
  box-sizing: border-box;
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  color: inherit;
  border-radius: ${themeGet('radii.2')};
  border-width: 0px;
  border-style: solid;
  border-color: ${themeGet('colors.base20')};

  ::placeholder {
    color: ${themeGet('colors.base40')};
  }

  ${disable}
  ${shadow}
  ${borders}
  ${space}
  ${typography}
  ${styledColor}
  ${layout}
`;

Input.displayName = 'Input';

Input.isField = true;

Input.defaultProps = {
  margin: 0,
  pt: '14px',
  pb: '14px',
  pl: '12px',
  pr: '12px',
  fontSize: 1,
  fontWeight: 'light',
};

Input.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  ...styledColor.propTypes,
  ...borders.propTypes,
  ...space.propTypes,
  ...shadow.propTypes,
  ...typography.propTypes,
  ...layout.propTypes,
};

export default Input;
