import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { H4 } from '../Heading';
import ListingsCard from '../ListingsCard';
import Box from '../Box';
import Item from './components/Item';

const Wrapper = styled(ListingsCard)`
  border-radius: 4px;
`;

const ListHeader = styled(Box)`
`;

const ItemsList = ({ children, heading, props }) => (
  <Wrapper {...props}>
    {heading ? (
      <ListHeader>
        <H4>
          {heading}
        </H4>
      </ListHeader>
    ) : null}
    {children}
  </Wrapper>
);

ItemsList.Item = Item;

ListHeader.defaultProps = {
  p: '24px 30px 15px 30px',
};

export default ItemsList;

Wrapper.defaultProps = {
  flexDirection: 'column',
  mb: 7,
  p: '0',
  minHeight: 'unset',
};

ItemsList.defaultProps = {
  heading: '',
  children: null,
};

ItemsList.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  ...ListingsCard.propTypes,
};
