import React from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components';
import Container, { NoScrollBodyStyle } from './components/Container';
import ModalBox from './components/ModalBox';
import Icon from '../Icon';
import Relative from '../Relative';

const StyledIcon = styled(Icon)`
  height: auto;
  position: absolute;
  right: 0;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors[p.closeHover]};
  }
  top: -50px;
  width: ${(p) => p.theme.sizes[8]};
  
  @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
    top: -40px;
    width: ${(p) => p.theme.sizes[7]};
  }
  
  @media (max-width: ${(p) => p.theme.breakpoints[0]}) {
    top: -30px;
    width: ${(p) => p.theme.sizes[6]};
  }
`;

const Close = ({ onClose, closeColor, closeHover }) => (
  <Relative width="100%" textAlign="right">
    <StyledIcon color={closeColor} closeHover={closeHover} name="Cross" onClick={onClose} />
  </Relative>
);

Close.defaultProps = {
  closeColor: 'base60',
  closeHover: 'base40',
};

Close.propTypes = {
  onClose: PropTypes.func.isRequired,
  closeColor: PropTypes.string,
  closeHover: PropTypes.string,
};

const Modal = ({
  vimeoId, close, overlayColor, closeColor, closeHover, closeWithOverlay, ...rest
}) => (
  <Container onClick={() => { if (closeWithOverlay) { close(); } }} backgroundColor={overlayColor}>
    <NoScrollBodyStyle />
    <ModalBox {...rest} boxShadow="none" bg="transparent" mt={[7, 8, 9]}>
      <Close onClose={close} closeColor={closeColor} closeHover={closeHover} />
      <Vimeo
        video={vimeoId}
        autoplay
        responsive
        onEnd={() => close()}
      />
    </ModalBox>
  </Container>
);

Modal.defaultProps = {
  ...ModalBox.defaultProps,
  overlayColor: 'opacity60',
  closeColor: 'base60',
  closeHover: 'base40',
  closeWithOverlay: true,
};

Modal.propTypes = {
  ...ModalBox.propTypes,
  vimeoId: PropTypes.string.isRequired,
  overlayColor: PropTypes.string,
  closeColor: PropTypes.string,
  closeHover: PropTypes.string,
  closeWithOverlay: PropTypes.bool,
};

export default Modal;
