import React from 'react';
import PropTypes from 'prop-types';
import ArrowBox from './ArrowBox';
import IconSection from './IconSection';

const FieldError = ({
  error, bg, titleColor, iconName, iconColor,
}) => (error ? (
  <ArrowBox bg={bg} left p="4" show>
    <IconSection
      iconColor={iconColor}
      titleColor={titleColor}
      iconName={iconName}
      title={error}
    />
  </ArrowBox>
) : null);

FieldError.defaultProps = {
  error: null,
  bg: 'errorLight',
  titleColor: 'error',
  iconName: 'WarningCircle',
  iconColor: 'error',
};

FieldError.propTypes = {
  error: PropTypes.string,
  bg: PropTypes.string,
  titleColor: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
};

export default FieldError;
