export const override = (theme, overrides) => {
  if (!overrides) return theme;
  const newTheme = {
    ...theme,
    ...overrides,
    colors: {
      ...theme.colors,
      ...overrides.colors,
      text: overrides.colors.base,
      white: '#fff',
      black: '#000',
      buildersWhite: '#FBFBFB',
      bg: overrides.colors.base05,
      muted: '#8D8080',
      hover: '#F2F4F5',
    },
  };
  return newTheme;
};

export const textTransform = ({
  uppercase, lowercase, capitalize, initial,
}) => {
  if (uppercase) return 'uppercase';
  if (lowercase) return 'lowercase';
  if (capitalize) return 'capitalize';
  if (initial) return 'initial';
  return 'initial';
};
