import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import Relative from '../Relative';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  &:hover {
    color: ${(p) => p.theme.colors.base80};
  }
`;

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${(p) => p.theme.colors.white};
  min-width: ${(p) => p.minWidth};
  transform: ${(p) => (p.open ? `translateX(${p.translateX.from})` : `translateX(${p.translateX.to})`)};
  height: 100%;
  padding-top: ${(p) => p.theme.space[11]};
  position: fixed;
  top: 0;
  right: ${(p) => p.right};
  left: ${(p) => p.left};
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.25);
  overflow-y: auto;
  padding: 32px;
  z-index: ${(p) => p.zIndex};

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const SideBar = ({
  children, minWidth, open, positioning, setOpen, translateX, zIndex,
}) => {
  const right = positioning.position === 'right';
  const left = positioning.position === 'left';
  let posLeft = '';
  let posRight = '';
  if (right) {
    posLeft = '';
    posRight = positioning.distance;
  } else if (left) {
    posRight = '';
    posLeft = positioning.distance;
  }

  return (
    <StyledMenuContainer left={posLeft} minWidth={minWidth} open={open} right={posRight} translateX={translateX} zIndex={zIndex}>
      <Relative height="16px" width="100%" textAlign="right">
        <StyledIcon size="16px" color="base60" name="Cross" onClick={() => setOpen(false)} />
      </Relative>
      {children}
    </StyledMenuContainer>
  );
};

SideBar.defaultProps = {
  minWidth: '320px',
  positioning: { position: 'left', distance: '0' },
  translateX: { from: '0', to: '-500px' },
  zIndex: '200',
};

SideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  minWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  positioning: PropTypes.shape({
    position: PropTypes.string,
    distance: PropTypes.string,
  }),
  setOpen: PropTypes.func.isRequired,
  translateX: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  zIndex: PropTypes.string,
};

export default SideBar;
