import styled from 'styled-components';
import Box from './Box';

const Blockquote = styled(Box)`
  border-left: 10px solid ${(p) => p.theme.colors.primary};
`;

Blockquote.defaultProps = {
  py: [3, 4, 5],
  pl: [3, 5, 6, 7],
};

export default Blockquote;
