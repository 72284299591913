/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Flex from '../Flex';
import Icon from '../Icon';

const AccordionWrapper = styled(Flex)`
  cursor: pointer;
  font-size: ${((p) => themeGet(`fontSizes.${p.fontSize}`, p.fontSize)(p))};
`;

AccordionWrapper.defaultProps = {
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'center',
};

const AccordionHeader = styled(Flex)`
  font-size: ${((p) => themeGet(`fontSizes.${p.headingFontSize}`, p.headingFontSize)(p))};
  font-weight: ${(p) => p.theme.fontWeights[p.headingFontWeight]};
  transition: all 0.3s ease-in-out;
  padding: ${(p) => (p.type === 'step' ? `18px ${p.theme.space[4]} 18px 30px` : '18px 30px')};
`;

AccordionHeader.defaultProps = {
  alignItems: 'center',
  justifyContent: 'space-between',
};

const InternalWrapper = styled.div`
  background-color: ${(p) => p.theme.colors[p.contentBg]};
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  padding: ${(p) => ((p.type === 'task' || p.type === 'step')
    ? p.isOpen
      ? '32px 32px 25px 32px'
      : '0 32px'
    : '')};
  transition: all 0.3s ease-in-out;
  width: 100%;
  cursor: initial;
`;

const HeaderIcon = styled(Icon)`
  transform: rotate(${(props) => (props.isOpen ? '90' : '0')}deg);
  transition: all 0.2s;
  fill: ${(props) => (props.isOpen ? props.theme.colors.base : props.theme.colors.base40)};
`;

const DefaultHeaderIcon = styled(Icon)`
  transform: rotate(${(props) => (props.isOpen ? '180' : '0')}deg);
  transition: all 0.2s;
`;

const RemoveIcon = styled(Icon)`
  cursor: pointer;
`;

const AccordionRow = ({
  headerProps, contentBg, type, fontSize, heading, headingFontSize, headingFontWeight, children, isOpen, isRemovable, dismiss, isComplete, icon, iconColor, ...rest
}) => (
  <AccordionWrapper {...rest} fontSize={fontSize} isComplete={isComplete} flexDirection="column">
    <AccordionHeader {...headerProps} isOpen={isOpen} headingFontSize={headingFontSize} headingFontWeight={headingFontWeight} type={type}>
      <Flex alignItems="center" flexDirection="row">
        {!!(type === 'step' && icon) && <Icon name={icon} size="18px" mr={4} color={iconColor} />}
        {!!(type === 'task' && isComplete) && <Icon name="CheckCircle" size="18px" mr={3} color="success" /> }
        {!!(type === 'task' && !isComplete) && <HeaderIcon isOpen={isOpen} name="NavRight" size="12px" mr={3} />}
        {heading}
      </Flex>
      {!!(isComplete && type === 'task') && <RemoveIcon color="base60" name="Cross" onClick={dismiss} size="18px" /> }
      {!!(isRemovable && isOpen && !isComplete && type === 'task') && <RemoveIcon color="base60" name="Cross" onClick={dismiss} size="18px" />}
      {!!(type === 'default') && <DefaultHeaderIcon color="secondary" isOpen={isOpen} name="NavDown" size="12px" />}
      {!!(type === 'step' && children) && <DefaultHeaderIcon isOpen={isOpen} name="NavDown" size="12px" />}
    </AccordionHeader>
    <InternalWrapper isOpen={isOpen} contentBg={contentBg} type={type} onClick={(e) => e.stopPropagation()}>
      {children}
    </InternalWrapper>
  </AccordionWrapper>
);

AccordionRow.defaultProps = {
  contentBg: 'transparent',
  dismiss: null,
  fontSize: '18px',
  headingFontSize: '',
  headingFontWeight: 'bold',
  isComplete: false,
  isRemovable: false,
  type: 'default',
  icon: null,
  iconColor: null,
};

AccordionRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  contentBg: PropTypes.string,
  dismiss: PropTypes.func,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  headingFontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  headingFontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  isComplete: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isRemovable: PropTypes.bool,
  type: PropTypes.oneOf(['task', 'default', 'step']),
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  ...Flex.propTypes,
};

export default AccordionRow;
