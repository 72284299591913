export default function mediaQueriesFactory(css, theme) {
  if (!css) throw new Error(`Invalid argument - ThemedCssFunction was expected, received ${css}`);
  if (!theme) throw new Error(`Invalid argument - a theme object was expected, received ${theme}`);

  const breakpoints = { ...theme.breakpoints };

  /**
   * Creates a media query for everything above threshold.
   * @param {number} threshold minimum width
   *
   */
  const greaterThan = Object.keys(breakpoints).reduce(
    (accumulator, threshold) => {
      accumulator[threshold] = (...args) => css`
        @media (min-width: ${breakpoints[threshold]}) {
          ${css(...args)};
        }
      `;
      return accumulator;
    }, {},
  );

  /**
   * Creates a media query for everything below threshold.
   * @param {number} threshold maximum width
   */
  const lessThan = Object.keys(breakpoints).reduce(
    (accumulator, threshold) => {
      accumulator[threshold] = (...args) => css`
        @media (max-width: ${breakpoints[threshold]}) {
          ${css(...args)};
        }
      `;
      return accumulator;
    }, {},
  );

  /**
   * Creates a media query within a range.
   * @param {[number, number]} thresholds min & max targeted thresholds
   */
  const between = ([min, max]) => (...args) => css`
    @media (min-width: ${breakpoints[min]}) and (max-width: ${breakpoints[max]}) {
      ${css(...args)};
    }
  `;

  return { greaterThan, lessThan, between };
}
