import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  border,
  compose,
  color,
  flexbox,
  layout,
  space,
  typography,
} from 'styled-system';
import Icon from '../Icon';

const StyledLink = styled.button`
  background: transparent;
  border: 0;
  color: ${(p) => themeGet(`colors.${p.color}`)(p)};
  cursor: pointer;
  text-decoration: ${(p) => p.textDecoration};
  &:active, &:visited {
    color: ${(p) => themeGet(`colors.${p.color}`)(p)};
  }
  &:hover {
    color: ${(p) => themeGet(`colors.${p.color}Dark`)(p)};
    & > ${Icon} {
      fill: ${(p) => themeGet(`colors.${p.color}Dark`)(p)};
    }
  }
  ${compose(flexbox, layout, border, color, space, typography)}
`;

const Link = ({
  to, url, children, setOpen, onClick, textDecoration, ...rest
}) => {
  const history = useHistory();

  const navigate = () => {
    if (setOpen) setOpen(false);
    if (to) history.push(to);
    if (onClick) onClick();
    if (url) window.location.href = url;
  };

  return (
    <StyledLink onClick={navigate} textDecoration={textDecoration} {...rest}>
      {children}
    </StyledLink>
  );
};

StyledLink.defaultProps = {
  alignItems: 'center',
  color: 'secondary',
  display: 'flex',
  flexDirection: 'row',
};

Link.defaultProps = {
  children: null,
  fontSize: 1,
  fontWeight: 'light',
  onClick: null,
  setOpen: null,
  textAlign: 'left',
  textDecoration: 'underline',
  to: null,
  url: null,
};

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fontWeight: PropTypes.string,
  url: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  setOpen: PropTypes.func,
  onClick: PropTypes.func,
  fontSize: PropTypes.number,
  textAlign: PropTypes.string,
  textDecoration: PropTypes.string,
  ...border.PropTypes,
  ...color.PropTypes,
  ...flexbox.PropTypes,
  ...layout.PropTypes,
  ...space.PropTypes,
  ...typography.PropTypes,
};

export default Link;
