import React from 'react';
import styled, { withTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import {
  layout, space, typography, letterSpacing, border,
} from 'styled-system';
import Icon from '../Icon';
import Box from '../Box';

const leftIconPlaceholder = (leftIcon) => {
  if (leftIcon) return '';
  return `
    :before {
      content: '';
      width: 20px;
    }
  `;
};
const rightIconPlaceholder = (rightIcon) => {
  if (rightIcon) return '';
  return `
    :after {
      content: '';
      width: 20px;
    }
  `;
};

const getFullWidth = ({
  fullWidth, leftIcon, rightIcon, color,
}) => {
  const fullWidthStyle = `
    width: 100%;
    justify-content: space-between;
    padding: 11px;
    &:hover {
      padding: ${color === 'outline' ? '10px' : '11px'} ${color === 'outline' ? '10px' : '11px'};
    }
    ${rightIconPlaceholder(rightIcon)}
    ${leftIconPlaceholder(leftIcon)}
  `;
  if (fullWidth) return fullWidthStyle;
  return '';
};

const getIconStyles = (props) => {
  let defaultColor = props.color === 'primary' || props.color === 'secondary' || props.color === 'tertiary' ? props.theme.colors.white : props.theme.colors.primary;
  if (props.color === 'base') defaultColor = props.theme.colors.base60;
  const color = props.iconColor ? themeGet(`colors.${props.iconColor}`, props.color)(props) : defaultColor;
  const defaultSize = props.size === 'small' ? '12px' : '24px';
  const size = props.iconSmall ? '12px' : defaultSize;
  return `
    color: ${color};
    width: ${size};
    height: ${size};
    vertical-align: text-top;
  `;
};

const getSize = (props) => {
  const small = `
    min-width: ${props.minWidth ? props.minWidth : '70px'};
    font-size: ${props.theme.fontSizes[0]};
    line-height: 20px;
    padding: 5px 20px;
    & {
      &:hover {
        padding: ${props.color === 'outline' ? '4px' : '5px'} ${props.color === 'outline' ? '19px' : '20px'};
      }
    }
  `;

  const medium = `
  min-width: ${props.minWidth ? props.minWidth : '130px'};
    font-size: ${props.theme.fontSizes[0]};
    line-height: 24px;
    padding: 11px 30px;
    & { 
      &:hover {
        padding: ${props.color === 'outline' ? '10px' : '11px'} ${props.color === 'outline' ? '29px' : '30px'};
      }
    }
  `;

  switch (props.size) {
    case 'small':
      return small;
    case 'medium':
      return medium;
    default:
      return medium;
  }
};

const getVariant = (props) => {
  const outline = `
    background-color: ${props.theme.colors.white};
    color: ${props.theme.colors.secondary};
    border-width: 1px;
    &:hover {
      background-color: ${props.theme.colors.white};
      color: ${props.theme.colors.base60};
      border-width: 2px;
    }
    border-color: ${props.theme.colors.base20};
  `;

  const base = `
    background-color: ${props.theme.colors.base10};
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: ${props.theme.colors.text};
    &:hover {
      background-color: ${(props.disabled ? props.theme.colors.base10 : props.theme.colors.base20)};
    }
  `;

  const secondary = `
    background-color: ${props.theme.colors.secondary};
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: ${props.theme.colors.white};
    &:hover {
      background-color: ${(props.disabled ? props.theme.colors.secondary : props.theme.colors.secondaryDark)};
    }
  `;

  const tertiary = `
    background-color: ${props.theme.colors.tertiary};
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: ${props.theme.colors.white};
    &:hover {
      background-color: ${(props.disabled ? props.theme.colors.tertiary : props.theme.colors.tertiaryDark)};
    }
  `;

  const baseDark = `
    background-color: ${props.theme.colors.base};
    border: 1px solid ${props.theme.colors.base};
    color: ${props.theme.colors.white};
    &:hover {
      background-color: ${(props.disabled ? props.theme.colors.base : props.theme.colors.baseDark)};
    }
  `;

  const link = `
    background-color: transparent;
    border: 1px solid transparent;
    color: ${props.theme.colors.secondary};
    &:hover {
      color: ${props.theme.colors.base60};
    }
  `;

  const primary = `
    background-color: ${props.theme.colors.primary};;
    color: ${props.theme.colors.white};
    &:hover {
      background-color: ${(props.disabled ? props.theme.colors.primary : props.theme.colors.primaryDark)};
    }
  `;

  switch (props.color) {
    case 'outline':
      return outline;
    case 'base':
      return base;
    case 'secondary':
      return secondary;
    case 'tertiary':
      return tertiary;
    case 'baseDark':
      return baseDark;
    case 'link':
      return link;
    case 'primary':
      return primary;
    default:
      return secondary;
  }
};

const Button = styled.button`
  user-select: none;
  text-decoration: none;
  text-transform: ${(p) => p.textTransform};
  display: inline-block;
  cursor: pointer;
  border-width: 0;
  border-style: solid;
  border-radius: ${(props) => (props.square ? props.theme.radii[0] : props.theme.radii[5])};
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: ${(props) => (props.noWrap ? 'nowrap' : 'normal')};

  &:disabled{
    cursor:not-allowed;
  }

  ${Icon} {
    ${getIconStyles}
  }

  ${getVariant};
  ${getSize};
  ${getFullWidth}

  ${space};
  ${layout};
  ${typography};
  ${letterSpacing};
  ${border};
`;

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  color: PropTypes.oneOf(['outline', 'link', 'base', 'primary', 'secondary', 'tertiary', 'baseDark']),
  ...layout.propTypes,
  ...space.propTypes,
  ...border.propTypes,
  fullWidth: PropTypes.bool,
  square: PropTypes.bool,
  letterSpacing: PropTypes.string,
  leftIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  iconColor: PropTypes.string,
  iconSmall: PropTypes.bool,
  noWrap: PropTypes.bool,
  textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize', 'none']),
};

const WrappedButton = ({
  children, leftIcon, rightIcon, ...props
}) => (
  <Button
    leftIcon={leftIcon}
    rightIcon={rightIcon}
    {...props}
  >
    {leftIcon}
    <Box px="4">{children}</Box>
    {rightIcon}
  </Button>
);

WrappedButton.defaultProps = {
  letterSpacing: 'button',
  color: 'secondary',
  iconColor: null,
  iconSmall: false,
  noWrap: false,
  textTransform: 'uppercase',
};

WrappedButton.propTypes = {
  ...Button.propTypes,
};

export default withTheme(WrappedButton);
