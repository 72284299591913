import styled from 'styled-components';
import { space } from 'styled-system';
import Box from '../../Box';

const ModalBox = styled(Box)`
  max-width: ${p => (p.maxWidth || '750px')};
  @media (max-width: ${p => p.theme.breakpoints[0]}) {
    min-height: 100%;
  }
`;

ModalBox.defaultProps = {
  ml: 'auto',
  mr: 'auto',
  mt: [0, 4, 7, 8],
  mb: [0, 4, 7, 8],
  p: 0,
  borderRadius: [0, 3],
  bg: 'white',
  boxShadow: ['none', 'lg'],
  width: '100%',
  maxWidth: '750px',
};

ModalBox.propTypes = {
  ...space.propTypes,
};

export default ModalBox;
