import styled from 'styled-components';
import {
  maxWidth,
  height,
  borderRadius,
} from 'styled-system';

import Box from '../Box';

const themed = key => props => props.theme[key];

const Image = styled(Box)({},
  maxWidth,
  height,
  borderRadius,
  themed('Image'));

Image.propTypes = {
  ...maxWidth.propTypes,
  ...height.propTypes,
  ...borderRadius.propTypes,
};

Image.defaultProps = {
  maxWidth: '100%',
  height: 'auto',
  as: 'img',
  m: 0,
};

export default Image;
