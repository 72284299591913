import styled from 'styled-components';
import { space } from 'styled-system';

const RadioWrap = styled.label`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: ${(props) => props.theme.fontSizes[1]};
  color: ${(props) => props.theme.colors.borderGray};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  margin-top: ${(props) => (props.mt && props.theme.space[props.mt]) || 0};
  margin-right: ${(props) => (props.mr && props.theme.space[props.mr]) || 0};
  margin-bottom: ${(props) => (props.mb && props.theme.space[props.mb]) || 0};
  margin-left: ${(props) => (props.ml && props.theme.space[props.ml]) || 0};
`;

RadioWrap.defaultProps = {
  mb: 3,
  mr: 6,
};

RadioWrap.propTypes = {
  ...space.propTypes,
};

export default RadioWrap;
